export const actions = {
  REGISTER_BRAND_ADMIN_REQUEST: "REGISTER_BRAND_ADMIN_REQUEST",
  REGISTER_BRAND_ADMIN_SUCCESS: "REGISTER_BRAND_ADMIN_SUCCESS",
  CREATE_BRAND_THEME_REQUEST: "CREATE_BRAND_THEME_REQUEST",
  CREATE_BRAND_THEME_SUCCESS: "CREATE_BRAND_THEME_SUCCESS",
  GET_BRAND_THEME_REQUEST: "GET_BRAND_THEME_REQUEST",
  GET_BRAND_THEME_SUCCESS: "GET_BRAND_THEME_SUCCESS",
  GET_BRAND_REQUEST: "GET_BRAND_REQUEST",
  GET_BRAND_SUCCESS: "GET_BRAND_SUCCESS",

  CATCH_ERROR: "CATCH_ERROR",

  registerBrandAdmin: (data, navigate) => {
    return {
      type: actions.REGISTER_BRAND_ADMIN_REQUEST,
      data,
      navigate,
    };
  },

  createBrandTheme: (data) => {
    return {
      type: actions.CREATE_BRAND_THEME_REQUEST,
      data,
    };
  },

  getBrandTheme: (brand) => {
    return {
      type: actions.GET_BRAND_THEME_REQUEST,
      brand,
    };
  },

  getBrand: () => {
    return {
      type: actions.GET_BRAND_REQUEST,
    };
  },
};

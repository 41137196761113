import { BlobServiceClient } from "@azure/storage-blob";
const blobServiceClient = new BlobServiceClient(
  `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME}?${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_SASS_TOKEN}`
);

const themeContainerBlobServiceClient = new BlobServiceClient(
  `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${process.env.REACT_APP_AZURE_THEME_STORAGE_CONTAINER_NAME}?${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_SASS_TOKEN}`
);

// const blobServiceClient = new BlobServiceClient(
//   `https://routeresourcegroup.blob.core.windows.net/image?sp=racwdli&st=2023-05-08T11:38:12Z&se=2023-05-08T19:38:12Z&sv=2022-11-02&sr=c&sig=8hMIjUcus8Ck%2BFztliyD3DktGAUnB2vRxQfYWZ68opo%3D`
// );

const getBlobName = (uuid, mimetype) => {
  const imageType = mimetype.split("/")[1];
  return `${uuid}.${imageType}`;
};
// const uploadFile = async (file, name, containerName, additionalUploadProps) => {
//   try {
//     // console.log(file, name, containerName, additionalUploadProps);
//     const blobName = name;

//     // const containerName = process.env.INVITATION_CARD_THUMBNAIL_CONTAINER_NAME;
//     const containerClient = blobServiceClient.getContainerClient(containerName);
//     // Get a block blob client
//     const blockBlobClient = containerClient.getBlockBlobClient(blobName);
//     // eslint-disable-next-line
//     const uploadBlobResponse = await blockBlobClient.uploadData(
//       file,
//       additionalUploadProps
//     );
//     return blockBlobClient.url;
//   } catch (error) {
//     console.log("error", error);
//   }
// };

const uploadFile = async (file, name, containerName, additionalUploadProps) => {
  try {
    const blobName = name;
    let containerClient;

    // Check which container to use based on the containerName parameter
    if (containerName === process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME) {
      containerClient = blobServiceClient.getContainerClient(
        process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME
      );
    } else if (
      containerName === process.env.REACT_APP_AZURE_THEME_STORAGE_CONTAINER_NAME
    ) {
      containerClient = themeContainerBlobServiceClient.getContainerClient(
        process.env.REACT_APP_AZURE_THEME_STORAGE_CONTAINER_NAME
      );
    } else {
      throw new Error("Invalid container name");
    }

    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.uploadData(
      file,
      additionalUploadProps
    );
    return blockBlobClient.url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

const deleteBlob = async (blobName, containerName) => {
  try {
    let containerClient;

    // Check which container to use based on the containerName parameter
    if (containerName === process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME) {
      containerClient = blobServiceClient.getContainerClient(
        process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME
      );
    } else if (
      containerName === process.env.REACT_APP_AZURE_THEME_STORAGE_CONTAINER_NAME
    ) {
      containerClient = themeContainerBlobServiceClient.getContainerClient(
        process.env.REACT_APP_AZURE_THEME_STORAGE_CONTAINER_NAME
      );
    } else {
      throw new Error("Invalid container name");
    }

    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const deleteResponse = await blockBlobClient.delete();
    return deleteResponse;
  } catch (error) {
    console.error("Error deleting file:", error);
    throw error;
  }
};

// const deleteBlob = async (blobName, containerName) => {
//   try {
//     const containerClient = blobServiceClient.getContainerClient(containerName);
//     const res = await containerClient.deleteBlob(blobName);
//     return res;
//   } catch (error) {
//     console.log("error", error);
//     return error;
//   }
// };

export { getBlobName, uploadFile, deleteBlob };

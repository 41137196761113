import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./Header.css";
import { useDispatch, useSelector } from "react-redux";
import { actions as playersAction } from "../../redux/Player/action";
// import Input from "../Input";
// import Button from "../Button";
import Icons from "../../assets/icons/Icons";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import FeatherIcon from "feather-icons-react";
// import Input from "components/Input";
// import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// import { Button } from "primereact/button";
// import moment from "moment";
// import { uuid } from "utils/uuid";
import Filter from "components/Filters";
import { Sidebar } from "primereact/sidebar";

const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    page = "",
    selectedPositions,
    setSelectedPositions,
    selectedClubs,
    setSelectedClubs,
    keyword,
    setKeyword,
    setPlayers,
    operation,
    setOperation,
    selectedFilter,
    setSelectedFilter,
  } = props;
  // const [selectedPositions, setSelectedPositions] = useState();
  // const [selectedClubs, setSelectedClubs] = useState();
  // const [keyword, setKeyword] = useState("");
  // const [positions, setPosistions] = useState();
  const [visible, setVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { brandTheme } = useSelector((state) => state.brand);
  const [selectedStatus, setSelectedStatus] = useState([
    {
      id: "aa8bae6a-b686-7be4-7558-65fe9174d006",
      status: [],
      startDate: null,
      endDate: null,
    },
  ]);

  const [date, setDate] = useState([
    {
      id: "aa8bae6a-b686-7be4-7558-65fe9174d006",
      date: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ],
    },
  ]);
  const [dynamicFilterUI, setDynamicFilterUI] = useState([
    "aa8bae6a-b686-7be4-7558-65fe9174d006",
  ]);
  // const [selectedFilter, setSelectedFilter] = useState([
  //   {
  //     id: "aa8bae6a-b686-7be4-7558-65fe9174d006",
  //     status: [],
  //     startDate: null,
  //     endDate: null,
  //     positions: [],
  //     clubs: [],
  //   },
  // ]);
  const [open, setOpen] = useState(false);
  // const [dynamicFilterUI, setDynamicFilterUI] = useState([
  //   "aa8bae6a-b686-7be4-7558-65fe9174d006",
  // ]);
  // const [openDateRangePicker, setOpenDateRangePicker] = useState({
  //   id: 0,
  //   open: false,
  // });
  const token = localStorage.getItem("TOKEN");
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }
  const { club, playerStatus: playerStatuses } = useSelector(
    (state) => state.players
  );

  const { playerCategory: playerCategories } = useSelector(
    (state) => state.players
  );

  const status =
    playerStatuses &&
    playerStatuses?.length > 0 &&
    playerStatuses?.map((status) => {
      return { name: status.name, value: status.id };
    });

  const { user } = useSelector((state) => state.user);
  const positions =
    club &&
    club.positions?.length > 0 &&
    club.positions.map((position) => {
      return { name: position, value: position };
    });

  positions &&
    positions?.length > 0 &&
    positions.sort((a, b) => a?.name.localeCompare(b?.name));

  const clubs =
    club &&
    club.club?.length > 0 &&
    club.club.map((club) => {
      return { name: club.name, value: club.id };
    });
  clubs &&
    clubs?.length > 0 &&
    clubs.sort((a, b) => a?.name.localeCompare(b?.name));

  const handleChangePosition = (e) => {
    setSelectedPositions(e.target.value);
    setPlayers([]);
  };

  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value);
    setPlayers([]);
  };

  const handleChangeCategory = (val) => {
    setSelectedCategory(val);
    setPlayers([]);
  };

  const back = () => {
    navigate("/");
  };

  useEffect(() => {
    if (page === "players" && decoded?.role !== "admin") {
      dispatch(
        playersAction.getAllPlayers({
          clubId: user?.club?.id,
          keyword: keyword,
          skip: 0,
          body: {
            positions: selectedPositions,
            brandId: brandTheme?.id,
          },
          category: selectedCategory,
        })
      );
    } else {
      dispatch(
        playersAction.getAllPlayers({
          keyword: keyword,
          category: selectedCategory,
          skip: 0,
          body: {
            positions: selectedPositions,
            clubId: selectedClubs,
            brandId: brandTheme?.id,
            // filter: { filterData: selectedFilter, operation: operation },
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [
    selectedPositions,
    keyword,
    selectedClubs,
    user,
    dispatch,
    page,
    token,
    selectedCategory,
    // selectedFilter,
    // operation,
  ]);

  return (
    <>
      <div className="container-fluid">
        <div className="text-green fw-bolder fs-5 p-3 back_btn">
          <div
            // className="d-flex justify-content-end headerDiv"
            className={`d-flex ${
              decoded?.role === "clubAdmin" ||
              user?.role === "clubAdmin" ||
              decoded?.role === "tutor" ||
              user?.role === "tutor"
                ? "justify-content-between"
                : "justify-content-end"
            }  headerDiv`}
          >
            {(decoded?.role === "clubAdmin" ||
              user?.role === "clubAdmin" ||
              decoded?.role === "tutor" ||
              user?.role === "tutor") && (
              <span className="d-flex headerDivBackButton" onClick={back}>
                <Icons name="leftArrow" />
                <span className="ms-2">Back</span>
              </span>
            )}
            {/* mt-lg-0 mt-sm-4 */}
            <div className="">
              <div className="d-flex justify-content-center align-items-start">
                {(decoded?.role === "admin" || user?.role === "admin") && (
                  <div className="text-end d-inline-block me-2 sidebarOuterDiv shadow-sm">
                    <div
                      className="d-flex justify-content-end btn btn-outline-secondary p-2 font-light archivedButton position-relative"
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "6px",
                      }}
                      onClick={() => navigate("/players/archived")}
                    >
                      <span>
                        <FeatherIcon icon="archive" size={16} />
                      </span>
                      <p className="ps-2">Archived</p>
                    </div>
                  </div>
                )}

                {/* <div
                  className="me-2 sidebarOuterDiv"
                  id="multiSelectedPositionsDiv"
                >
                  <div className="dropdownFilter poppins">
                    <div
                      className="d-flex align-items-center filterDiv p-1 px-3 shadow-sm"
                      onClick={() => setOpen(!open)}
                    >
                      <p className="text-secondary filterText me-2">Filter</p>
                      <div>
                        <FeatherIcon
                          icon="filter"
                          size={16}
                          className="text-secondary"
                          // onClick={handleClick}
                        />
                      </div>
                    </div>
                    {open && (
                      <div className="dropdown-content poppins">
                        <Filter
                          selectedStatus={selectedStatus}
                          setSelectedStatus={setSelectedStatus}
                          open={open}
                          setOpen={setOpen}
                          clubs={clubs}
                          positions={positions}
                          status={status}
                          selectedPositions={selectedPositions}
                          handleChangePosition={handleChangePosition}
                          selectedClubs={selectedClubs}
                          setSelectedClubs={setSelectedClubs}
                          setPlayers={setPlayers}
                          setSelectedFilter={setSelectedFilter}
                          selectedFilter={selectedFilter}
                          setOperation={setOperation}
                          operation={operation}
                          setDynamicFilterUI={setDynamicFilterUI}
                          dynamicFilterUI={dynamicFilterUI}
                          setDate={setDate}
                          date={date}
                        />
                      </div>
                    )}
                  </div>
                </div> */}

                {/* <div
                  className="me-2 sidebarOuterDiv"
                  id="multiSelectedPositionsDiv"
                >
                  {positions && (
                    <MultiSelect
                      value={selectedPositions}
                      options={positions}
                      onChange={handleChangePosition}
                      optionLabel="name"
                      placeholder="Select Position"
                      filter
                      className="multiselect-custom "
                      maxSelectedLabels={0}
                      selectedItemsLabel={"{0} items selected"}
                      disabled={open ? true : false}
                    />
                  )}
                </div> */}
                {(page === "master" || decoded?.role === "admin") && (
                  <div
                    className="me-2 sidebarOuterDiv"
                    id="multiSelectedClubDiv"
                  >
                    {clubs && (
                      <MultiSelect
                        value={selectedClubs}
                        options={clubs}
                        onChange={(e) => {
                          setSelectedClubs(e.target.value);
                          setPlayers([]);
                        }}
                        optionLabel="name"
                        placeholder="Select Club"
                        filter
                        className="multiselect-custom"
                        maxSelectedLabels={0}
                        selectedIt1emsLabel={"{0} items selected"}
                        disabled={open ? true : false}
                      />
                    )}
                  </div>
                )}

                <div className="mt-md-0 mt-xs-2 search_boxDiv">
                  <div className="search_box">
                    <div className="input-group">
                      {/* <Input
                      type="search"
                      placeholder="What're you searching for?"
                      className="form-control border-0"
                      id="search"
                    /> */}
                      <input
                        type="search"
                        placeholder="What're you searching for?"
                        className="form-control border-0"
                        id="search"
                        value={keyword}
                        onChange={handleChangeKeyword}
                      />

                      <div className="input-group-append">
                        <button
                          id="button-addon1"
                          type="submit"
                          className="btn btn-link text-primary"
                        >
                          <Icons
                            name="search"
                            stroke={brandTheme?.themeColors?.primary}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <p className="font-light text-secondary smallFont">
                    Search by players, club, and position name wise
                  </p>
                </div>
                <div className="mt-md-0 mt-xs-2 search_boxDiv">
                  <div className="search_box">
                    <div className="input-group">
                      <select
                        className="form-control border-0"
                        value={selectedCategory}
                        onChange={(e) => handleChangeCategory(e.target.value)}
                      >
                        <option value="">All Categories</option>
                        {playerCategories.map((item) => (
                          <option
                            className="form-control border-0"
                            key={item.id}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => setVisible(true)}
                  className="btn btn-outline-secondary ms-2 p-2 px-3 d-none menuButton"
                >
                  <FeatherIcon
                    icon="menu"
                    size={16}
                    className="text-secondary"
                    // onClick={handleClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <Sidebar
          visible={visible}
          onHide={() => setVisible(false)}
          className="w-full sidebarMainDiv"
        >
          {/* <h2>Sidebar</h2> */}
          <div className="">
            <div className="sidebarInnerDiv" id="multiSelectedPositionsDiv">
              {/* <div className="dropdownFilter poppins">
                <div
                  className="d-flex align-items-center filterDiv p-2 px-3"
                  onClick={() => setOpen(!open)}
                >
                  <p className="text-secondary filterText me-2">Filter</p>
                  <div>
                    <FeatherIcon
                      icon="filter"
                      size={16}
                      className="text-secondary"
                      // onClick={handleClick}
                    />
                  </div>
                </div>
                {open && (
                  <div className="dropdown-content poppins">
                    <Filter
                      selectedStatus={selectedStatus}
                      setSelectedStatus={setSelectedStatus}
                      open={open}
                      setOpen={setOpen}
                      clubs={clubs}
                      positions={positions}
                      status={status}
                      selectedPositions={selectedPositions}
                      handleChangePosition={handleChangePosition}
                      selectedClubs={selectedClubs}
                      setSelectedClubs={setSelectedClubs}
                      setPlayers={setPlayers}
                      setSelectedFilter={setSelectedFilter}
                      selectedFilter={selectedFilter}
                      setOperation={setOperation}
                      operation={operation}
                      setDynamicFilterUI={setDynamicFilterUI}
                      dynamicFilterUI={dynamicFilterUI}
                      setDate={setDate}
                      date={date}
                    />
                  </div>
                )}
              </div> */}
            </div>

            {/* <div
              className="mt-1 sidebarInnerDiv"
              id="multiSelectedPositionsDiv"
            >
              {positions && (
                <MultiSelect
                  value={selectedPositions}
                  options={positions}
                  onChange={handleChangePosition}
                  optionLabel="name"
                  placeholder="Select Position"
                  filter
                  className="multiselect-custom "
                  maxSelectedLabels={0}
                  selectedItemsLabel={"{0} items selected"}
                  disabled={open ? true : false}
                />
              )}
            </div> */}
            {(page === "master" || decoded?.role === "admin") && (
              <div className="mt-2 sidebarInnerDiv" id="multiSelectedClubDiv">
                {clubs && (
                  <MultiSelect
                    value={selectedClubs}
                    options={clubs}
                    onChange={(e) => {
                      setSelectedClubs(e.target.value);
                      setPlayers([]);
                    }}
                    optionLabel="name"
                    placeholder="Select Club"
                    filter
                    className="multiselect-custom"
                    maxSelectedLabels={0}
                    selectedIt1emsLabel={"{0} items selected"}
                    disabled={open ? true : false}
                  />
                )}
              </div>
            )}
          </div>
        </Sidebar>
      </div>
    </>
  );
};

export default Header;

import Button from "components/Button";
import Heading from "components/Heading";
import Image from "components/Image";
import Input from "components/Input";
import React, { useEffect, useRef, useState } from "react";
import "./AddClub.css";
import FeatherIcon from "feather-icons-react";
import Label from "components/Label";
import { actions } from "redux/Club/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { actions as courseActions } from "redux/Course/action";
import { actions as loaderActions } from "redux/Loader/action";

function AddClub({ club, setClubDataUpdate }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // eslint-disable-next-line
  const { courses, institutions, institutionTypes } = useSelector(
    (state) => state.course
  );
  const { networkProgressDialog } = useSelector((state) => state.loader);

  const imgRef = useRef(null);
  const closeRef = useRef(null);
  let { clubAdmins } = useSelector((state) => state.tutor);
  let clubAdminData = clubAdmins?.find((clubAdmin) => {
    return (
      clubAdmin?.club?.id === club?.id &&
      clubAdmin?.role === "clubAdmin" &&
      clubAdmin
    );
  });
  // eslint-disable-next-line
  const [instituteOption, setInstituteOption] = useState({
    name: "",
    value: club?.instituteId ? club?.instituteId : "",
  });
  const [instituteTypeOption, setInstituteTypeOption] = useState({
    name: "",
    value: club?.instituteTypeId ? club?.instituteTypeId : "",
  });
  // eslint-disable-next-line
  const [clubAdmin, setClubAdmin] = useState(clubAdminData);
  // const [clubUpdate, setClubUpdate] = useState(club);
  // eslint-disable-next-line
  const [clubImgValue, setClubImgValue] = useState(null);
  const [clubImg, setClubImg] = useState(club?.image ? club?.image : null);
  const [clubData, setClubData] = useState({
    name: club?.name ? club?.name : "",
    email: club?.email ? club?.email : clubAdminData?.email,
    image: club?.image ? club?.image : "",
  });
  const [error, setError] = useState("");
  const [disableClubBtn, setDisableClubBtn] = useState(false);

  const handleClick = (event) => {
    imgRef.current.click();
  };

  const handleChangeImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file?.type === "image/png") {
      setError("");
      const formData = new FormData();
      formData.append("image", file);
      axios
        .post("/club/image", formData)
        .then((res) => {
          if (res.data) setClubData({ ...clubData, image: res?.data?.result });
        })
        .catch((error) => {
          console.log(error);
        });
      reader.onload = (e) => {
        const result = e.target.result;
        // setClubImgValue(result);
        setClubImg(result);
        // setClubData({ ...clubData, image: result });

        // setClubData({
        //   ...clubData,
        //   image:
        //     "https://routeresourcegroup.blob.core.windows.net/image/166729731334431F4C334-D342-4814-930A-F922563ECFDD.png",
        // });
      };
      setDisableClubBtn(true);

      reader.readAsDataURL(file);
    } else {
      setError("Please upload only png image!!");
    }
  };

  const handleChange = (event) => {
    setClubData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setDisableClubBtn(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors =
      (!club && !clubData.email) ||
      !clubData.image ||
      !clubData.name ||
      !instituteOption?.value ||
      !instituteTypeOption?.value;
    if (errors) {
      setError("All fields are required!");
    } else {
      !club?.id
        ? dispatch(
            actions.createClub(
              {
                ...clubData,
                instituteId: instituteOption?.value,
                instituteTypeId: instituteTypeOption?.value,
              },
              navigate
            )
          )
        : dispatch(
            actions.updateClub({
              ...clubData,
              id: club.id,
              email: clubAdminData?.email ? "" : clubData?.email,
              instituteId: instituteOption?.value,
              instituteTypeId: instituteTypeOption?.value,
            })
          );
      club?.id &&
        setClubDataUpdate({
          ...clubData,
          id: club.id,
          instituteId: club && instituteOption?.value,
          instituteTypeId: club && instituteTypeOption?.value,
        });
      setClubData({
        name: club && clubData?.name ? clubData?.name : "",
        email: club && clubData?.email ? clubData?.email : clubAdminData?.email,
        image: club && clubData?.image ? clubData?.image : "",
        instituteId: club && instituteOption?.value,
        instituteTypeId: club && instituteTypeOption?.value,
      });
      setClubImg(club && clubData?.image ? clubData?.image : null);
      closeRef.current.click();
    }
  };

  const handleCancel = () => {
    setClubData({
      name: club?.name ? club?.name : "",
      email: club?.email
        ? club?.email
        : clubAdminData?.email
        ? clubAdminData?.email
        : clubAdmin?.email,
      image: club?.image ? club?.image : "",
    });
    setClubImg(club?.image ? club?.image : null);
    setInstituteOption({
      name: "",
      value: club?.instituteId ? club?.instituteId : "",
    });
    setInstituteTypeOption({
      name: "",
      value: club?.instituteTypeId ? club?.instituteTypeId : "",
    });
    setError("");
  };

  // const handleAddEmail = () => {
  //   setClubAdmin({
  //     ...clubAdmin,
  //     email: clubAdminData?.email ? clubAdminData?.email : "",
  //   });
  //   setClubData({ ...clubData, email: "" });
  //   clubAdminData.email = "";
  // };

  useEffect(() => {
    setClubData({
      ...clubData,
      email: club?.email
        ? club?.email
        : clubAdminData?.email
        ? clubAdminData?.email
        : clubAdmin?.email,
    });

    // const existInstitution = institutions.find((data) => {
    //   return data.id === club?.instituteId;
    // });
    setInstituteOption({
      // name: existInstitution.name,
      name: "",
      value: club?.instituteId ? club?.instituteId : "",
    });
    // if (club) {
    //   dispatch(
    //     courseActions.getInstitutionTypes({ instituteId: club?.instituteId })
    //   );
    // }
    // eslint-disable-next-line
  }, [clubAdminData]);

  // useEffect(() => {
  //   if (club) {
  //     // dispatch(
  //     //   courseActions.getInstitutionTypes({ instituteId: club?.instituteId })
  //     // );
  //   }
  // }, []);

  // useEffect(() => {
  //   dispatch(courseActions.getInstitutions());
  // }, []);

  useEffect(() => {
    setInstituteOption({
      // name: existInstitution.name,
      name: "",
      value: club?.instituteId ? club?.instituteId : "",
    });
    setInstituteTypeOption({
      name: "",
      value: club?.instituteTypeId ? club?.instituteTypeId : "",
    });
  }, [club]);

  const [institutionsOptions, setInstitutionsOptions] = useState([]);
  const [institutionTypeOptions, setInstitutionTypeOptions] = useState([]);

  useEffect(() => {
    const options = institutions.map((data) => {
      return { name: data?.name?.toLowerCase(), value: data.id };
    });
    setInstitutionsOptions(options);
  }, [institutions]);

  useEffect(() => {
    const options = institutionTypes.map((data) => {
      return { name: data.name?.toLowerCase(), value: data.id };
    });

    setInstitutionTypeOptions(options);
    if (club) {
      setInstituteTypeOption({
        name: "",
        value: club?.instituteTypeId ? club?.instituteTypeId : "",
      });
    }
  }, [institutionTypes, club]);

  return (
    <>
      {/* <div className="container"> */}
      <div className="addNewClubDiv p-2 bg-white poppins">
        {/* <Heading type="h5" label="Add New Club" />
        <hr className="divider" /> */}
        <div className="modal-header">
          <Heading
            type="h5"
            label={club ? `Update Organisation` : "Add New Organisation"}
            className="modal-title fw-bold"
            id="staticBackdropLabel"
          />
          <Button
            className="btn-close"
            attribute={{ "data-bs-dismiss": "modal", "aria-label": "Close" }}
            handleClick={handleCancel}
          />
          <p
            className="d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref={closeRef}></p>
        </div>
        <div className="modal-body">
          <form>
            <div className="text-center mb-3 mt-1">
              <div
                className={`text-center ${
                  !clubImg
                    ? "d-flex flex-column justify-content-center align-items-center"
                    : "d-grid placeItem"
                } mx-auto choosePhotoDiv`}
                onClick={handleClick}>
                {!clubImg && (
                  <>
                    <FeatherIcon
                      icon="camera"
                      size={24}
                      className="text-muted"
                    />
                    <p className="mt-2">Choose Photo</p>
                  </>
                )}

                {clubImg && (
                  <Image src={clubImg} className="img-fluid addClubImage" />
                )}
              </div>

              <input
                type="file"
                ref={imgRef}
                // id="clubImage"
                // name="clubImage"
                className="d-none"
                // divclassName="d-none"
                value={clubImgValue}
                onChange={handleChangeImage}
                accept="image/*"
              />
              <Label className="mt-2" title="Organisation Image" />
            </div>
            <div className="mb-3">
              <Input
                type="text"
                className="inputField mt-2 px-2"
                id="clubName"
                label="Organisation Name"
                name="name"
                divStyle={{ fontSize: "18px" }}
                value={clubData.name}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {!club?.id && (
              <div className="mb-3">
                <Input
                  type="email"
                  // className={`inputField mt-2 px-2 ${
                  //   clubAdminData?.email ? "readOnly text-secondary" : ""
                  // }`}
                  className={`inputField mt-2 px-2`}
                  id="clubEmail"
                  name="email"
                  label="Owner Email"
                  divStyle={{ fontSize: "18px" }}
                  value={clubData.email}
                  onChange={(e) => handleChange(e)}
                  // readOnly={clubAdminData?.email ? true : false}
                />
              </div>
            )}
            <div className="mb-3">
              <Dropdown
                value={instituteOption.value}
                options={institutionsOptions}
                onChange={(e) => {
                  const isExistInstitute = institutionsOptions.find((data) => {
                    return data.value === e.value;
                  });
                  setInstituteOption({
                    name: isExistInstitute?.name,
                    value: e.value,
                  });
                  setDisableClubBtn(true);
                  dispatch(loaderActions.startLoader());
                  dispatch(
                    courseActions.getInstitutionTypes({ instituteId: e.value })
                  );
                }}
                optionLabel="name"
                placeholder="Select organization type"
                className="w-100 text-start"
                // disabled={data?.resStatus ? true : false}
              />
            </div>
            {instituteOption.value && (
              <div className="mb-3">
                <Dropdown
                  value={instituteTypeOption.value}
                  options={
                    networkProgressDialog
                      ? [{ name: "Loading...", value: "Loading..." }]
                      : institutionTypeOptions
                  }
                  onChange={(e) => {
                    setInstituteTypeOption(e);
                    setDisableClubBtn(true);
                  }}
                  optionLabel="name"
                  placeholder={`Select ${instituteOption.name} type`}
                  className="w-100 text-start"
                  // disabled={data?.resStatus ? true : false}
                />
              </div>
            )}
            {/* {clubAdminData?.email && (
              <div className="text-end">
                <Button
                  className="btn btn-outline-secondary me-2"
                  label="Add Email"
                  type="button"
                  handleClick={handleAddEmail}
                />
              </div>
            )} */}

            {/* <hr className="divider mt-4" />
          <div className="text-end mt-4">
            <Button
              className="btn btn-outline-secondary me-2"
              label="Cancel"
              handleClick={handleCancel}
            />
            <Button className="btn btn-success" label="Create" />
          </div> */}
            <p style={{ fontSize: "small" }} className="fw-bold text-danger">
              {error}
            </p>
          </form>
        </div>
        <div className="modal-footer">
          <Button
            className="btn btn-outline-secondary me-2"
            label="Cancel"
            attribute={{ "data-bs-dismiss": "modal" }}
            handleClick={handleCancel}
          />
          <Button
            className="btn btn-success btn-create-club border-color-primary background-primary"
            disabled={
              club?.id ? disableClubBtn === false : disableClubBtn === false
            }
            label={club?.id ? `Update` : "Create"}
            handleClick={handleSubmit}
          />
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default AddClub;

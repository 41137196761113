import { UnitCard } from "components/CourseCard";
import Navbar from "components/Navbar";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as loaderActions } from "redux/Loader/action";
import { actions as courseActions } from "redux/Course/action";
import "./AllCourses.css";
import { actions } from "redux/Course/action";
import { useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import Image from "components/Image";
import Heading from "components/Heading";
import plus1 from "assets/images/plus1.png";
import CreateCourse from "components/CreateCourse";
import { INSTITUTE_ID_ACADEMIC } from "utils/constants";
import { useLocation } from "react-router-dom";
import { useActiveTab } from "./hook";

const AllCourses = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const menuRef = useRef();
  // location.state = "kEnil";
  const {
    courses,
    institutions,
    institutionTypes,
    qualifications,
    previousFilterOfCourse,
  } = useSelector((state) => state.course);
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const [dropdownValueUnitCard, setDropdownValueUnitCard] = useState({
    id: "",
    open: false,
  });
  const [institutionTypeOptions, setInstitutionTypeOptions] = useState([]);
  const [instituteTypeOption, setInstituteTypeOption] = useState({
    name: "All",
    value: "all",
  });
  const [instituteOption, setInstituteOption] = useState({
    id: INSTITUTE_ID_ACADEMIC,
  });
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [qualificationState, setQualificationState] = useState({
    name: "All",
    value: "all",
  });
  const [activeTab, setActiveTab] = useActiveTab("");
  // location.state = {
  //   instituteId: INSTITUTE_ID_ACADEMIC,
  //   instituteTypeId: "all",
  //   qualificationId: "all",
  // };
  const [dropDownShowQualification, setDropDownShowQualification] =
    useState(false);

  const [isCreateCourse, setIsCreateCourse] = useState(false);
  useEffect(() => {
    dispatch(loaderActions.startLoader());
    dispatch(actions.clearCourses());
    if (previousFilterOfCourse) {
      dispatch(
        actions.getCourses({
          instituteId: previousFilterOfCourse.instituteId
            ? previousFilterOfCourse.instituteId
            : INSTITUTE_ID_ACADEMIC,
          instituteTypeId: previousFilterOfCourse.instituteTypeId
            ? previousFilterOfCourse.instituteTypeId
            : "",
          qualificationId: previousFilterOfCourse.qualificationId
            ? previousFilterOfCourse.qualificationId
            : "",
        })
      );
      location.state = {
        ...location.state,
        instituteId: previousFilterOfCourse.instituteId
          ? previousFilterOfCourse.instituteId
          : INSTITUTE_ID_ACADEMIC,
        instituteTypeId: previousFilterOfCourse.instituteTypeId
          ? previousFilterOfCourse.instituteTypeId
          : "",
        qualificationId: previousFilterOfCourse.qualificationId
          ? previousFilterOfCourse.qualificationId
          : "",
      };
      setDropDownShowQualification(true);
      dispatch(
        courseActions.getInstitutionTypes({
          instituteId: previousFilterOfCourse?.instituteId
            ? previousFilterOfCourse?.instituteId
            : INSTITUTE_ID_ACADEMIC,
        })
      );
      if (previousFilterOfCourse?.qualificationId) {
        dispatch(
          actions.getQualifications({
            instituteTypeId: previousFilterOfCourse.instituteTypeId,
            loader: true,
          })
        );
      }

      setInstituteTypeOption({
        name: "",
        value: previousFilterOfCourse?.instituteTypeId
          ? previousFilterOfCourse?.instituteTypeId
          : "all",
      });
      setQualificationState({
        name: "",
        value: previousFilterOfCourse?.qualificationId
          ? previousFilterOfCourse?.qualificationId
          : "all",
      });
      setInstituteOption({
        id: previousFilterOfCourse?.instituteId
          ? previousFilterOfCourse?.instituteId
          : INSTITUTE_ID_ACADEMIC,
      });
      dispatch(actions.addToPreviousFilterCorse({}));
    } else {
      setDropDownShowQualification(false);
      dispatch(
        actions.getCourses({
          instituteId: INSTITUTE_ID_ACADEMIC,
        })
      );
      dispatch(
        courseActions.getInstitutionTypes({
          instituteId: INSTITUTE_ID_ACADEMIC,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    const options = institutionTypes.map((data) => {
      return { name: data?.name?.toLowerCase(), value: data.id };
    });
    setInstitutionTypeOptions([{ name: "All", value: "all" }, ...options]);
  }, [institutionTypes]);

  useEffect(() => {
    const qualificationOption = qualifications?.map((data) => {
      return { name: data?.name?.toLowerCase(), value: data.id };
    });
    setQualificationOptions(
      qualifications.length > 0
        ? [{ name: "All", value: "all" }, ...qualificationOption]
        : [...qualificationOption]
    );
  }, [qualifications]);

  useEffect(() => {
    let handler = (e) => {
      if (menuRef && !menuRef.current?.contains(e.target)) {
        setDropdownValueUnitCard(false);
      }
    };
    document.addEventListener("mousedown", handler);
  }, []);

  return (
    <>
      <Navbar />

      <div className="container-fluid mt-3">
        {/* Create courses to back*/}
        {isCreateCourse && (
          <div
            className="d-flex align-items-center text-secondary fw-bold"
            onClick={() => {
              setIsCreateCourse(false);
            }}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="feather feather-arrow-left cursor-pointer">
              <g>
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </g>
            </svg>
            <span className="ps-1 cursor-pointer">Back</span>
          </div>
        )}
        <div className="my-1 coursesTab">
          {!isCreateCourse && (
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              {institutions?.length > 0 &&
                institutions?.map((data, i) => (
                  <>
                    <li key={i} className="nav-item" role="presentation">
                      <button
                        onClick={(e) => {
                          dispatch(loaderActions.startLoader());
                          setInstituteOption({ id: data.id });
                          setDropDownShowQualification(false);
                          setInstituteTypeOption({ name: "all", value: "all" });
                          location.state = {
                            ...location.state,
                            instituteId: data.id,
                            instituteTypeId: null,
                          };
                          dispatch(actions.clearCourses());
                          dispatch(
                            actions.getCourses({
                              instituteId: data.id,
                            })
                          );
                          dispatch(
                            courseActions.getInstitutionTypes({
                              instituteId: data.id,
                            })
                          );
                        }}
                        // className="nav-link poppins text-dark "
                        className={`nav-link poppins text-dark ${
                          instituteOption?.id
                            ? data.id === instituteOption?.id
                              ? "active"
                              : ""
                            : data.id === INSTITUTE_ID_ACADEMIC
                            ? "active"
                            : ""
                        }`}
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true">
                        {data?.name ? data?.name : ""}
                      </button>
                    </li>
                  </>
                ))}
            </ul>
          )}

          {!isCreateCourse && (
            <div className="coursesFilter d-flex gap-4">
              <div>
                <Dropdown
                  value={instituteTypeOption.value}
                  onChange={(e) => {
                    setInstituteTypeOption(e);
                    dispatch(loaderActions.startLoader());
                    dispatch(actions.clearCourses());
                    if (e.value === "all") {
                      dispatch(
                        actions.getCourses({
                          instituteId: instituteOption.id,
                        })
                      );
                      location.state = {
                        ...location.state,
                        instituteTypeId: "all",
                      };
                    } else {
                      dispatch(
                        actions.getCourses({
                          instituteTypeId: e.value,
                        })
                      );
                      location.state = {
                        ...location.state,
                        instituteTypeId: e.value,
                      };
                    }
                    dispatch(
                      actions.getQualifications({
                        instituteTypeId: e.value,
                        loader: true,
                      })
                    );
                    setDropDownShowQualification(true);
                  }}
                  options={institutionTypeOptions}
                  optionLabel="name"
                  placeholder="Select a organisation type"
                  filter
                />
              </div>

              {dropDownShowQualification && (
                <div>
                  <Dropdown
                    value={qualificationState.value}
                    onChange={(e) => {
                      setQualificationState(e);
                      dispatch(loaderActions.startLoader());
                      dispatch(actions.clearCourses());
                      if (e.value === "all") {
                        dispatch(
                          actions.getCourses({
                            instituteTypeId: instituteTypeOption.value,
                          })
                        );
                      } else {
                        dispatch(
                          actions.getCourses({
                            instituteTypeId: instituteTypeOption.value,
                            qualificationId: e.value,
                          })
                        );
                        location.state = {
                          ...location.state,
                          qualificationId: e.value,
                        };
                      }
                    }}
                    options={qualificationOptions}
                    optionLabel="name"
                    placeholder="Select a qualification type"
                    filter
                  />
                </div>
              )}
            </div>
          )}

          {networkProgressDialog && !isCreateCourse ? (
            <div id="cover-spin" className="LoaderSpin">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {!isCreateCourse && !networkProgressDialog ? (
                <>
                  <div className="p-1">
                    <div
                      className="container-fluid d-flex gap-5 flex-wrap justify-content-center mt-5  mb-5"
                      ref={menuRef}>
                      <div
                        onClick={() => {
                          setIsCreateCourse(true);
                        }}>
                        <div className="CourseCardInfo">
                          <div className="title-white">
                            <div
                              className={`flex-shrink-0 d-flex align-items-center`}>
                              <Image
                                src={plus1}
                                alt=""
                                className="img-fluid w-100 club_image_card cardBodyAddCard"
                                style={{
                                  borderRadius: "10px",
                                  filter: "invert(1)",
                                }}
                              />
                            </div>
                            <div className="w-100">
                              <Heading
                                type="h5"
                                className={`mb-0 poppins text-truncate text-center text-white`}
                                label={"Add Course"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {courses &&
                        courses?.length > 0 &&
                        courses?.map((item) => (
                          <UnitCard
                            key={item.id}
                            course={item}
                            dropdownValueUnitCard={dropdownValueUnitCard}
                            setDropdownValueUnitCard={setDropdownValueUnitCard}
                            playerCentre="viewAllCourses"
                            isCreateCourse={isCreateCourse}
                          />
                        ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {!isCreateCourse && (
                    <div
                      className="d-flex justify-content-center mt-4"
                      onClick={() => {
                        setIsCreateCourse(true);
                      }}>
                      <div className="CourseCardInfo">
                        <div className="title-white">
                          <div
                            className={`flex-shrink-0 d-flex align-items-center`}>
                            <Image
                              src={plus1}
                              alt=""
                              className="img-fluid w-100 club_image_card cardBodyAddCard"
                              style={{
                                borderRadius: "10px",
                                filter: "invert(1)",
                              }}
                            />
                          </div>
                          <div className="w-100">
                            <Heading
                              type="h5"
                              className={`mb-0 poppins text-truncate text-center text-white`}
                              label={"Add Course"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <div className="d-flex flex-column align-items-center no-Result-Found">
                    <img
                      className="noResultImg"
                      src={no_results}
                      alt="No Results Found"
                    />
                    <span className="noResultsFound poppins">
                      Their are no courses available!
                    </span>
                  </div> */}
                </>
              )}
              {isCreateCourse && (
                <div className="mt-3">
                  <CreateCourse playerCentre={"superCourseCenter"} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AllCourses;

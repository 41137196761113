import React, { useEffect, useState } from "react";
import TransferableUnit from "./TransferableUnit";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Input from "components/Input";
import { Slider } from "primereact/slider";
import { InputText } from "primereact/inputtext";
import Textarea from "components/Textarea";
import { actions as playersAction } from "redux/Player/action";
import { useDispatch } from "react-redux";
import { set } from "date-fns";
import { useSelector } from "react-redux";
import { SweetAlert } from "utils/SweetAlert";
import Swal from "sweetalert2";

const TransferableSkills = ({ contentEditable, player, setPlayer }) => {
  const dispatch = useDispatch();
  const { players } = useSelector((state) => state.players);
  const { brandTheme } = useSelector((state) => state.brand);
  const [collapsible, setCollapsible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState("");
  const [skill, setSkill] = useState({
    name: "",
    comment: "",
    point: 0,
    isEnable: true,
    isIncludeInCv: true,
  });

  const [visible, setVisible] = useState(false);
  const handleCallBack = (id, data) => {
    if (
      id !== undefined &&
      id !== null &&
      data !== undefined &&
      data !== null
    ) {
      const isIndex = player.transferableSkills.transferableSkills.findIndex(
        (skill) => skill.id === id
      );
      if (isIndex !== -1) {
        player.transferableSkills.transferableSkills[isIndex].name = data.name;
        player.transferableSkills.transferableSkills[isIndex].comment =
          data.comment;
        player.transferableSkills.transferableSkills[isIndex].point = Number(
          data.point
        );
        player.transferableSkills.transferableSkills[isIndex].isEnable =
          data.isEnable;
      }
      player.transferableValues[id] = data;
      setPlayer(player);
    }
  };
  const handleCollapsible = () => {
    setCollapsible(!collapsible);
    const contents = document.getElementsByClassName("transferableContent");
    Array.from(contents).forEach((content) => {
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + 180 + "px";
      }
    });
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        style={{ color: brandTheme?.themeColors?.primary || "#006B00" }}
        onClick={() => {
          setVisible(false);
          setError("");
          setSkill({
            name: "",
            comment: "",
            point: 0,
            isEnable: true,
            isIncludeInCv: true,
          });
        }}
        className="p-button-text"
      />
      <Button
        label="Add"
        icon="pi pi-check"
        style={{
          backgroundColor: brandTheme?.themeColors?.primary || "#006B00",
          color: "#fff",
        }}
        onClick={() => {
          if (skill.name === "" || skill.point === 0 || skill.comment === "") {
            setError("Please fill all the fields!");
          } else {
            dispatch(
              playersAction.createTransferableSkill({
                userId: player.id,
                ...skill,
              })
            );
            setSkill({
              name: "",
              comment: "",
              point: 0,
              isEnable: true,
              isIncludeInCv: true,
            });
            setVisible(false);
            setError("");
          }
        }}
        autoFocus
      />
    </div>
  );

  const handleChange = (event) => {
    setSkill((prevState) => ({
      ...skill,
      [event.target.name]: event.target.value,
    }));
  };

  const onDelete = async (id) => {
    const result = await SweetAlert({
      title: "Are you sure? ",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: brandTheme
        ? brandTheme?.themeColors?.primary
        : "#006b00",
    });
    if (result.isConfirmed) {
      const filterdTransferableSkills =
        player.transferableSkills.transferableSkills.filter(
          (skill) => skill.id !== id
        );
      player.transferableSkills.transferableSkills = filterdTransferableSkills;
      setPlayer({ ...player });
      Swal.fire({
        title: "Deleted!",
        text: "Your profile field has been deleted.",
        icon: "success",
        confirmButtonColor: brandTheme
          ? brandTheme?.themeColors?.primary
          : "#006b00",
      });
    }
  };

  useEffect(() => {
    setIsActive(player?.transferableSkills?.isActive);
  }, [player]);

  return (
    <>
      <div id="transferableValuesSection" className="">
        <div
          id="transferableTitle"
          className={`sectionTitle collapsible ${collapsible ? "active2" : ""}`}
          onClick={handleCollapsible}
        >
          Transferable Skills
        </div>

        {collapsible && (
          <>
            {" "}
            <div className="transferableContent content subHeaderTransferable">
              <div className="d-flex enableSection">
                <p className="enableTitle poppins">Enable Section</p>
                <InputSwitch
                  checked={isActive}
                  onChange={(e) => {
                    setIsActive(e.value);
                    player.transferableSkills.isActive = e.value;
                    setPlayer({ ...player });
                  }}
                  // style={{ height: "20px" }}
                  disabled={contentEditable ? false : true}
                />
              </div>
              <div className="buttonTransferable">
                <Button
                  type="button"
                  label="Add New Skill"
                  icon="pi pi-plus"
                  outlined
                  size="small"
                  onClick={() => setVisible(true)}
                  disabled={contentEditable ? false : true}
                />
              </div>
            </div>
            <div className="transferableContent content transferableContent_">
              {player?.transferableSkills?.transferableSkills?.map(
                (skill, index) => {
                  return (
                    <TransferableUnit
                      key={index}
                      label={skill?.name}
                      name={skill?.name}
                      id={skill?.id}
                      contentEditable={contentEditable}
                      handleCallBack={handleCallBack}
                      value={{
                        value: skill?.value,
                        point: skill?.point,
                        isEnable: skill?.isEnable,
                        name: skill?.name,
                        comment: skill?.comment,
                      }}
                      onDelete={onDelete}
                    />
                  );
                }
              )}
              {/* <TransferableUnit
            label="Adaptability"
            id="adaptability"
            name="adaptability"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.adaptability}
          />
          <TransferableUnit
            label="Assertiveness"
            id="assertiveness"
            name="assertiveness"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.assertiveness}
          /> */}
            </div>
            <Dialog
              header="Add transferable skill"
              visible={visible}
              style={{ width: "400px", zIndex: 9999 }}
              onHide={() => {
                setVisible(false);
                setSkill({
                  name: "",
                  comment: "",
                  point: 0,
                  isEnable: true,
                  isIncludeInCv: true,
                });
              }}
              footer={footerContent}
            >
              <div>
                <Input
                  type="text"
                  className="inputField mt-2 px-2"
                  id="transferableSkillName"
                  name="name"
                  label="Skill Name"
                  divStyle={{ fontSize: "18px" }}
                  value={skill?.name}
                  onChange={(e) => handleChange(e)}
                />
                {/* <InputText
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="w-full"
            /> */}
                <Input
                  type="number"
                  className="inputField mt-2 px-2"
                  id="transferableSkillName"
                  name="point"
                  label="Skill Point"
                  divStyle={{ fontSize: "18px" }}
                  value={skill?.point >= 0 ? skill?.point : 0}
                  onChange={(e) => {
                    setSkill((prevState) => ({
                      ...skill,
                      point:
                        e.target.value >= 0 && e.target.value <= 10
                          ? e.target.value
                          : prevState.point,
                    }));
                  }}
                  max={10}
                  min={0}
                />
                <Slider
                  value={skill?.point}
                  onChange={(e) => setSkill({ ...skill, point: e.value })}
                  className="w-full"
                  // step={1}
                  min={0}
                  max={10}
                />
                <textarea
                  id={"Comment"}
                  className={"textAreaBox"}
                  name={"comment"}
                  value={skill?.comment}
                  onChange={(e) => handleChange(e)}
                  placeholder={"Comment here..."}
                  rows={4}
                  cols={50}
                />

                <div>
                  <p
                    id="login_validation"
                    style={{
                      color: "red",
                      fontSize: "small",
                      marginTop: "10px",
                    }}
                    className="fw-bold"
                  >
                    {error}
                  </p>
                </div>
              </div>
            </Dialog>
          </>
        )}
        {/* <div className="transferableContent content">
          <TransferableUnit
            label="Acceptance"
            id="acceptance"
            name="acceptance"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.acceptance}
          />
          <TransferableUnit
            label="Consideration"
            id="consideration"
            name="consideration"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.consideration}
          />
        </div>
        <div className="transferableContent content">
          <TransferableUnit
            label="Confidence"
            id="confidence"
            name="confidence"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.confidence}
          />
          <TransferableUnit
            label="Determination"
            id="determination"
            name="determination"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.determination}
          />
        </div>
        <div className="transferableContent content">
          <TransferableUnit
            label="Excellence"
            id="excellence"
            name="excellence"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.excellence}
          />
          <TransferableUnit
            label="Preparedness"
            id="preparedness"
            name="preparedness"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.preparedness}
          />
        </div>

        <div className="transferableContent content">
          <TransferableUnit
            label="Reliability"
            id="reliability"
            name="reliability"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.reliability}
          />
          <TransferableUnit
            label="Integrity"
            id="integrity"
            name="integrity"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.integrity}
          />
        </div>

        <div className="transferableContent content">
          <TransferableUnit
            label="Flexibility"
            id="flexibility"
            name="flexibility"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.flexibility}
          />
          <TransferableUnit
            label="Courage"
            id="courage"
            name="courage"
            contentEditable={contentEditable}
            handleCallBack={handleCallBack}
            value={player && player?.transferableValues?.courage}
          />
        </div> */}
      </div>
    </>
  );
};

export default TransferableSkills;

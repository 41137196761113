import Navbar from "components/Navbar";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Image from "components/Image";
import { TabView, TabPanel } from "primereact/tabview";
import Profile from "components/Profile";
import Password from "components/Password";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { Toast } from "utils/toast";
import { actions } from "redux/User/action";
import { useDispatch } from "react-redux";

const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [activeIndex, setActiveIndex] = useState(0);
  const { networkProgressDialog } = useSelector((state) => state.loader);
  // const handleCancel = () => {
  //   setUserData({
  //     name: "",
  //     email: user?.email ? user.email : "",
  //     image: "",
  //     designation: "",
  //   });
  //   setTutorImg(tutor?.image ? tutor.image : null);
  //   setError("");
  //   setPasswordValid(true);
  //   setViewPassword(false);
  // };

  const [userData, setUserData] = useState({
    name: user?.name ? user?.name : "",
    email: user?.email ? user?.email : "",
    image: user?.image ? user?.image : "",
    designation: user?.designation ? user?.designation : "",
  });

  const back = () => {
    if (user.name === "" || user.designation === "" || user.email === "") {
      Toast("info", "Please Add Your Profile Details!");
    } else {
      navigate("/");
    }
  };

  const handleRemove = () => {
    setUserData({
      ...userData,
      image: "http://s3.amazonaws.com/37assets/svn/765-default-avatar.png",
    });
    const formData = new FormData();
    formData.append(
      "image",
      "http://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
    );
    formData.append("name", userData.name);
    formData.append("email", userData.email);
    formData.append("designation", userData.designation);
    dispatch(actions.userUpdate(formData, "tutor"));
  };

  useEffect(() => {
    setUserData({
      name: user?.name ? user?.name : "",
      email: user?.email ? user?.email : "",
      image: user?.image ? user?.image : "",
      designation: user?.designation ? user?.designation : "",
    });
  }, [user]);

  return (
    <div>
      <Navbar />
      <div className="container-fluid mt-3">
        <div
          className="d-flex align-items-center text-secondary fw-bold"
          onClick={back}>
          <FeatherIcon icon="arrow-left" size={16} className="cursor-pointer" />
          <span className="ps-1 cursor-pointer">Back</span>
        </div>
      </div>
      <div className="container">
        <div className="w-75 my-5 mx-auto">
          <div className="d-flex align-items-end">
            <div className="profileImgDiv">
              <Image
                src={
                  userData.image
                    ? userData.image
                    : "http://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
                }
                // src="http://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
                alt=""
                className="full-width userProfile"
              />
              {userData.image !==
              "http://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
                ? userData?.image && (
                    <div className="chooseRemoveImgProfileDiv">
                      <div className="removeProfileImgDiv">
                        <FeatherIcon
                          icon="trash-2"
                          size={18}
                          onClick={handleRemove}
                        />
                      </div>
                    </div>
                  )
                : ""}
            </div>
            <div className="puppins ms-3 profileInfoMain">
              <p className="fw-bold">{user.name ? user.name : user.email}</p>
              <p className="text-secondary fw-bold">
                {user.designation ? user.designation : user.email}
              </p>
            </div>
          </div>

          <div className="my-4">
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}>
              <TabPanel header="Profile">
                <Profile user={user} />
              </TabPanel>
              <TabPanel header="Password">
                <Password />
              </TabPanel>
            </TabView>
          </div>
          {networkProgressDialog && (
            <div id="cover-spin">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Account;

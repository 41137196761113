import React from "react";
import FeatherIcon from "feather-icons-react";

function Search({ keyword, setKeyword, handleSearch }) {
  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value);
    handleSearch(e.target.value);
  };

  return (
    <div className="shadow-sm search_box mt-md-0 mt-xs-2 border border-color-primary rounded-0">
      <div className="input-group">
        {/* <Input
                      type="search"
                      placeholder="What're you searching for?"
                      className="form-control border-0"
                      id="search"
                    /> */}
        <input
          type="search"
          placeholder="What're you searching for?"
          className="form-control border-0"
          id="search"
          value={keyword}
          onChange={handleChangeKeyword}
        />
        <div className="input-group-append">
          <button
            id="button-addon1"
            type="submit"
            className="btn btn-link text-primary">
            <FeatherIcon icon="search" size={20} className="txt-primary" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Search;

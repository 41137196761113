import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "redux/Brand/action";
import { useNavigate } from "react-router-dom";
import { actions as loaderActions } from "redux/Loader/action";
import Navbar from "components/Navbar";
import { useSelector } from "react-redux";

const RegisterBrand = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const [formData, setFormData] = useState({
    name: "",
    brandName: "",
    email: "",
    image: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loaderActions.startLoader());
    dispatch(actions.registerBrandAdmin({ ...formData }, navigate));
    setFormData({
      name: "",
      brandName: "",
      email: "",
      image: "",
    });
  };

  return (
    <div>
      <Navbar />
      <div className="container mt-5 puppins">
        <h2 className="mb-4">Register Brand Admin</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="brandName" className="form-label">
              Brand Name
            </label>
            <input
              type="text"
              className="form-control"
              id="brandName"
              name="brandName"
              value={formData.brandName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn greenButton">
            {networkProgressDialog ? (
              <div className="loader w-100" id="loader">
                <div className="text-center d-flex">
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ width: "20px", height: "20px" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              "Register"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegisterBrand;

import grid_dot from "assets/images/grid_dot.png";
import Image from "components/Image";
import Navbar from "components/Navbar";
import {
  AddUserProfileCard,
  CreateProfileField,
  UserProfileCard,
} from "components/UserProfileCard";
import EditProfileField from "components/UserProfileCard/EditProfileField";
import FeatherIcon from "feather-icons-react";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { actions as loaderActions } from "redux/Loader/action";
import { actions } from "redux/Profile/action";
import Swal from "sweetalert2";
import { SweetAlert } from "utils/SweetAlert";
import "./OrganizationUserProfile.css";

const OrganizationUserProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const closeRef = useRef();
  const [showProfileField, setShowProfileField] = useState(false);
  const [createNewProfile, setCreateNewProfile] = useState(false);
  // eslint-disable-next-line
  const [dynamicProfileField, setDynamicProfileField] = useState(false);
  const [arrayProfileField, setArrayProfileField] = useState(null);
  const [organizationProfileField, setOrganizationProfileField] =
    useState(false);
  const [updateProfileField, setUpdateProfileField] = useState({
    title: "",
    type: "",
    options: [],
    showInCV: false,
  });
  const [error, setError] = useState({
    message: "",
    name: "",
  });
  const [options, setOptions] = useState([]);
  const [profileFieldsOfOrganisation, setProfileFieldsOfOrganisation] =
    useState([]);
  const [arrayField, setArrayField] = useState({
    arrayType: "",
    placeholder: null,
    objectFields: [],
    noOfField: 0,
    placeholderBool: 0,
  });
  // eslint-disable-next-line
  const [disableProfileFieldBtn, setDisableProfileFieldBtn] = useState(false);

  const { user } = useSelector((state) => state.user);
  const { brandTheme } = useSelector((state) => state.brand);
  const {
    dynamicProfileFields,
    organizationProfileFields,
    priorityProfileFields,
  } = useSelector((state) => state.profile);
  const { networkProgressDialog } = useSelector((state) => state.loader);

  useEffect(() => {
    dispatch(loaderActions.startLoader());
    // dispatch(actions.getProfilesFields());
    // eslint-disable-next-line
    {
      user &&
        dispatch(
          actions.getDynamicProfilesFields({
            instituteId: user?.club?.instituteId,
            instituteTypeId: user?.club?.instituteTypeId,
          })
        );
    }
    // eslint-disable-next-line
    {
      user &&
        dispatch(
          actions.getOrganizationProfilesFields({
            instituteId: user?.club?.instituteId,
            instituteTypeId: user?.club?.instituteTypeId,
            organizationId: user?.club?.id,
          })
        );
    }
    user &&
      dispatch(
        actions.getPriorityProfilesFields({
          organizationId: user?.club?.id,
        })
      );
  }, [dispatch, user]);

  const optionList = [
    // {
    //   id: 1,
    //   title: "Edit & duplicate",
    //   // navigate: "/",
    //   icon: "edit-3",
    //   attribute: {
    //     "data-bs-toggle": "modal",
    //     "data-bs-target": "#myModalEditProfile",
    //   },
    //   onClick: (data) => {
    //     setDynamicProfileField(false);
    //     setUpdateProfileField(data);
    //     const options_ = data?.options ? structuredClone(data?.options) : [];
    //     setOptions([...options_]);
    //   },
    // },
    {
      id: 1,
      title: "Add to my organisation",
      // navigate: "/",
      icon: "plus-circle",
      // attribute: {
      //   "data-bs-toggle": "modal",
      //   "data-bs-target": "#myModalEditProfile",
      // },
      onClick: (data) => {
        // eslint-disable-next-line
        {
          !organizationProfileFields
            ? dispatch(
                actions.createNewOrganizationProfile(
                  {
                    instituteId: user?.club?.instituteId,
                    instituteTypeId: user?.club?.instituteTypeId,
                    organisationId: user?.club?.id,
                    profileFields: [{ ...data, superProfileFieldId: data.id }],
                    multiple: false,
                  },
                  setShowProfileField
                )
              )
            : dispatch(
                actions.createOrganizationProfileField(
                  {
                    id: organizationProfileFields.id,
                    newProfileData: [{ ...data, superProfileFieldId: data.id }],
                    multiple: false,
                  },
                  setShowProfileField
                )
              );
        }
      },
    },
  ];

  const optionListOrganizationProfile = [
    {
      id: 1,
      title: "Update",
      // navigate: "/",
      icon: "edit-3",
      attribute: {
        "data-bs-toggle": "modal",
        "data-bs-target": "#myModalEditProfile",
      },
      onClick: (data) => {
        if (data.priority) {
          delete data.priority;
        }
        setDynamicProfileField(true);
        setOrganizationProfileField(true);
        setUpdateProfileField(data);
        const copyData = structuredClone(data);
        setArrayProfileField(copyData);
        const options_ = data?.options ? structuredClone(data?.options) : [];
        setOptions([...options_]);
      },
    },
    {
      id: 2,
      title: "Delete",
      icon: "trash-2",
      onClick: async (data) => {
        const result = await SweetAlert({
          title: "Are you sure? ",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          confirmButtonColor: brandTheme
            ? brandTheme?.themeColors?.primary
            : "#006b00",
        });
        if (result.isConfirmed) {
          dispatch(
            actions.deleteOrganizationProfileField({
              id: organizationProfileFields.id,
              profileFieldId: data.id,
            })
          );
          Swal.fire({
            title: "Deleted!",
            text: "Your profile field has been deleted.",
            icon: "success",
            confirmButtonColor: brandTheme
              ? brandTheme?.themeColors?.primary
              : "#006b00",
          });
        }
      },
    },
  ];

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result = result.map((data, i) => {
      data.priority = i + 3;
      return data;
    });
    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px !important",

    // change background colour if dragging
    background: isDragging ? "lightgrey" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#006b00" : "lightgrey",
    padding: grid,
    // width: 250,
  });

  const [items, setItems] = useState(
    priorityProfileFields?.profileFields
      ? priorityProfileFields?.profileFields?.slice(2)
      : []
  );

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updatedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(updatedItems);
  };

  useEffect(() => {
    setItems(priorityProfileFields?.profileFields?.slice(2));
  }, [priorityProfileFields]);

  useEffect(() => {
    if (
      organizationProfileFields &&
      organizationProfileFields.profileFields.length > 0 &&
      priorityProfileFields &&
      priorityProfileFields.profileFields.length > 0
    ) {
      const profileFields_ = organizationProfileFields.profileFields
        .map((data) => {
          const priorityProfile = priorityProfileFields.profileFields.find(
            (item) => item.id === data.id
          );
          if (priorityProfile) {
            data.priority = priorityProfile.priority;
          }
          return data;
        })
        .sort((a, b) => a.priority - b.priority);
      setProfileFieldsOfOrganisation([...profileFields_]);
    } else {
      setProfileFieldsOfOrganisation(
        organizationProfileFields?.profileFields
          ? [...organizationProfileFields?.profileFields]
          : []
      );
    }
  }, [priorityProfileFields, organizationProfileFields]);

  const onSavePriorityProfile = () => {
    dispatch(loaderActions.startLoader());
    dispatch(
      actions.updatePriorityProfilesFields({
        id: priorityProfileFields ? priorityProfileFields?.id : "",
        profileFields: [
          priorityProfileFields.profileFields[0],
          priorityProfileFields.profileFields[1],
          ...items,
        ],
      })
    );
  };

  useEffect(() => {
    if (!dynamicProfileFields || !organizationProfileFields) return;

    // Check if all data objects have a matching organizationField using every method
    const allHaveMatchingField = dynamicProfileFields?.profileFields?.every(
      (data) =>
        organizationProfileFields?.profileFields?.some(
          (item) => item.superProfileFieldId === data.id
        )
    );

    setDisableProfileFieldBtn(allHaveMatchingField);
  }, [dynamicProfileFields, organizationProfileFields]);

  return (
    <>
      <Navbar />

      {/* This back button show when display size minimum 768px  */}
      {showProfileField === true && (
        <div
          className="container-fluid d-flex align-items-center text-secondary fw-bold pt-2 backBtnHide"
          onClick={() => setShowProfileField(false)}
        >
          <FeatherIcon icon="arrow-left" size={16} className="cursor-pointer" />
          <span className="ps-1 cursor-pointer">Back</span>
        </div>
      )}

      <div className="container-fluid">
        <div className="row user-profile-row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-4 profile-card-div">
            <AddUserProfileCard
              setShowProfileField={setShowProfileField}
              setCreateNewProfile={setCreateNewProfile}
            />
          </div>

          {/* User-profile-card */}

          {showProfileField === false ? (
            <>
              {networkProgressDialog ? (
                <div id="cover-spin" className="LoaderSpin">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-8 profile-card-div my-4 organization-profile-card-div">
                  <TabView>
                    <TabPanel header="Assigned Profile Fields">
                      <div>
                        <div className="d-grid user-profile-card-grid">
                          {profileFieldsOfOrganisation?.map((item) => {
                            return (
                              <>
                                <UserProfileCard
                                  profileField={item}
                                  optionList={optionListOrganizationProfile}
                                  key={item?.id}
                                />
                              </>
                            );
                          })}
                        </div>
                        {(organizationProfileFields?.profileFields?.length <=
                          0 ||
                          !organizationProfileFields) && (
                          <div className="text-center poppins fw-bold">
                            <p className="text-secondary">
                              There are no profile fields available.
                            </p>
                            <p
                              className="text-success text-decoration-underline fs-5 cursor-pointer"
                              onClick={() => {
                                setCreateNewProfile(true);
                                setShowProfileField(true);
                              }}
                            >
                              Assign a new profile field?
                            </p>
                          </div>
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel header="Available Profile Fields">
                      <div>
                        {dynamicProfileFields?.profileFields?.length >= 0 && (
                          <div className="text-end ms-auto mb-3">
                            <button
                              className="btn btn-success border-color-primary background-primary button-primary"
                              disabled={disableProfileFieldBtn}
                              onClick={() => {
                                const dynamicProfileData = [];
                                dynamicProfileFields?.profileFields?.forEach(
                                  (data) => {
                                    const organizationField =
                                      organizationProfileFields?.profileFields?.find(
                                        (item) =>
                                          item.superProfileFieldId === data.id
                                      );
                                    data.superProfileFieldId = data.id;

                                    if (!organizationField) {
                                      dynamicProfileData.push(data);
                                    }
                                  }
                                );

                                !organizationProfileFields
                                  ? dispatch(
                                      actions.createNewOrganizationProfile(
                                        {
                                          instituteId: user?.club?.instituteId,
                                          instituteTypeId:
                                            user?.club?.instituteTypeId,
                                          organisationId: user?.club?.id,

                                          profileFields: dynamicProfileData
                                            ? dynamicProfileData
                                            : [],
                                          multiple: true,
                                        },
                                        setShowProfileField
                                      )
                                    )
                                  : dispatch(
                                      actions.createOrganizationProfileField(
                                        {
                                          id: organizationProfileFields.id,
                                          newProfileData: dynamicProfileData
                                            ? dynamicProfileData
                                            : [],
                                          multiple: true,
                                        },
                                        setShowProfileField
                                      )
                                    );
                              }}
                            >
                              <FeatherIcon
                                className={`${"mr-2 mb-1"}`}
                                icon={`${"plus-circle"}`}
                                size={20}
                                strokeWidth={3}
                              />
                              Add all profile fields to the organisation
                            </button>
                          </div>
                        )}
                        <div className="d-grid user-profile-card-grid">
                          {dynamicProfileFields?.profileFields?.length > 0 &&
                            dynamicProfileFields?.profileFields?.map((item) => {
                              const profileField =
                                organizationProfileFields?.profileFields
                                  ?.length > 0 &&
                                organizationProfileFields?.profileFields?.find(
                                  (data) =>
                                    data?.superProfileFieldId === item?.id
                                );
                              return (
                                <>
                                  <UserProfileCard
                                    profileField={item}
                                    optionList={optionList}
                                    key={item?.id}
                                    displayIconOfAdded={
                                      profileField ? true : false
                                    }
                                  />
                                </>
                              );
                            })}
                        </div>
                        {(dynamicProfileFields?.profileFields?.length <= 0 ||
                          !dynamicProfileFields) && (
                          <div className="text-center poppins fw-bold">
                            <p className="text-secondary mt-5">
                              There are no profile fields available.
                            </p>
                            {/* <p
                              className="text-success text-decoration-underline fs-5 cursor-pointer"
                              onClick={() => {
                                setCreateNewProfile(true);
                                setShowProfileField(true);
                              }}
                            >
                              Assign a new profile field?
                            </p> */}
                          </div>
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel header="Reorder Profile Fields">
                      <div>
                        <div className="text-end ms-auto mb-3">
                          <button
                            // label="save"
                            className="btn btn-success border-color-primary background-primary button-primary"
                            onClick={() => {
                              onSavePriorityProfile();
                            }}
                          >
                            save
                          </button>
                        </div>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {items?.map((item, index) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                    // index={
                                    //   index < 2 ? items.length + index : index
                                    // }
                                    // isDragDisabled={
                                    //   item.id === PROFILE_FIELD_NAME_ID ||
                                    //   item.id === PROFILE_FIELD_IMAGE_ID
                                    //     ? true
                                    //     : false
                                    // }
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                        className="d-flex justify-content-between"
                                      >
                                        <p> {item?.title?.toUpperCase()}</p>
                                        <Image
                                          src={grid_dot}
                                          style={{ width: "25px" }}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-8 profile-card-div my-4">
                <div className="d-grid">
                  <div
                    className="d-flex align-items-center text-secondary fw-bold profile-backBtnHide"
                    onClick={() => setShowProfileField(false)}
                  >
                    <FeatherIcon
                      icon="arrow-left"
                      size={16}
                      className="cursor-pointer"
                    />
                    <span className="ps-1 cursor-pointer">Back</span>
                  </div>
                  <CreateProfileField
                    steps={location?.state?.steps?.step_1}
                    setShowProfileField={setShowProfileField}
                    createNewProfile={createNewProfile}
                    createProfileFieldFromOrganization={true}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div
          className="modal fade"
          id="myModalEditProfile"
          data-bs-keyboard="false"
          data-bs-backdrop="static"
        >
          <div className={`modal-dialog modal-dialog-centered`}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Profile Field</h4>
                <button
                  type="button"
                  className="btn-close me-1"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div className="modal-body">
                <EditProfileField
                  updateProfileField={updateProfileField}
                  setUpdateProfileField={setUpdateProfileField}
                  options={options}
                  setArrayField={setArrayField}
                  arrayField={arrayField}
                />
                {error.message && (
                  <p
                    id="login_validation"
                    style={{ fontSize: "small" }}
                    className="text-danger mt-3"
                  >
                    {error.message}
                  </p>
                )}
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                  ref={closeRef}
                  onClick={() => {
                    setUpdateProfileField({ ...arrayProfileField });
                    setArrayField({
                      ...arrayField,
                      value: arrayProfileField?.value,
                    });
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-success border-color-primary background-primary button-primary"
                  // data-bs-dismiss="modal"
                  onClick={() => {
                    if (updateProfileField?.title !== "") {
                      if (updateProfileField?.type?.toLowerCase() === "array") {
                        delete arrayField.noOfField;
                        delete arrayField.placeholderBool;
                      }
                      if (organizationProfileField) {
                        dispatch(
                          actions.updateOrganizationProfileField({
                            id: organizationProfileFields.id,
                            profileData:
                              updateProfileField?.type?.toLowerCase() ===
                              "array"
                                ? [
                                    {
                                      ...updateProfileField,
                                      isContainsMedia:
                                        arrayField?.objectFields?.find(
                                          (item) => item.type === "media"
                                        )
                                          ? true
                                          : false,
                                      ...arrayField,
                                    },
                                  ]
                                : [updateProfileField],
                          })
                        );
                      }
                      closeRef.current.click();
                      setError({ ...error, message: "" });
                    } else {
                      setError({
                        ...error,
                        message: "Please fill The field name!",
                      });
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationUserProfile;

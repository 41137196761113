import Button from "components/Button";
import Heading from "components/Heading";
import Image from "components/Image";
import Input from "components/Input";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./AddTutor.css";
import FeatherIcon from "feather-icons-react";
import Label from "components/Label";
import { actions } from "redux/Tutor/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CropImage from "components/CropImage";

function AddTutor({ tutor, setTutorDataUpdate }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const imgRef = useRef(null);
  const closeRef = useRef(null);
  const { user } = useSelector((state) => state.user);
  const [viewPassword, setViewPassword] = useState(false);
  const [tutorImg, setTutorImg] = useState(tutor?.image ? tutor?.image : null);
  const [tutorImgValue, setTutorImgValue] = useState(
    tutor?.image ? tutor?.image : null
  );
  const [tutorData, setTutorData] = useState({
    name: tutor?.name ? tutor?.name : "",
    email: tutor?.email ? tutor?.email : "",
    image: tutor?.image ? tutor?.image : "",
    designation: tutor?.designation ? tutor?.designation : "",
    clubId: user?.club ? user?.club?.id : params?.id ? params?.id : "",
    password: "",
    cPassword: "",
  });
  const [error, setError] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);

  const [croppedImage, setCroppedImage] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageCallback = useCallback(
    async (imageFileStream) => {
      setCroppedImage(imageFileStream);
      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target.result;
        setTutorImg(result);
        setTutorData({ ...tutorData, image: result });
      };

      reader.readAsDataURL(imageFileStream);

      const formData = new FormData();
      formData.append("image", imageFileStream);
      // axios
      //   .post("/club/image?containerName=image", formData)
      //   .then((res) => {
      //     if (res?.data) {
      //       setTutorImg(res?.data?.result);
      //       setTutorData({ ...tutorData, image: res?.data?.result });
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    // eslint-disable-next-line
    [croppedImage]
  );

  const handleClick = (event) => {
    imgRef.current.click();
  };

  const handleChangeImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target.result;
      // setTutorImg(result);
      setTutorImgValue(result);
      setCropModal(true);
      // setTutorImgValue(result);
      setTutorData({ ...tutorData, image: result });
    };

    reader.readAsDataURL(file);
  };

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&`:|.;*])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
    return re.test(str);
  }

  const handleChange = (event) => {
    if (event.target.name === "password") {
      setPasswordValid(checkPassword(event.target.value));
      // if (checkPassword(event.target.value));
    }

    setTutorData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors =
      !tutorData.email ||
      !tutorData.name ||
      !tutorData.designation ||
      (!tutor && (!tutorData.password || !tutorData.cPassword));
    if (errors) {
      // setError("Email, Name, Designation, and Image are required!");
      setError("All fields are required!");
    } else {
      if (tutorData.password !== tutorData.cPassword) {
        setError("Password Does Not Match");
      } else {
        delete tutorData.cPassword;
        // tutor && delete tutorData.password;
        tutor && delete tutorData.clubId;
        setTutorData({
          ...tutorData,
          clubId: user?.club ? user?.club?.id : params?.id ? params?.id : "",
        });
        const formData = new FormData();
        formData.append(
          "image",
          croppedImage ? croppedImage : tutorData?.image
        );
        formData.append("name", tutorData.name);
        formData.append("email", tutorData.email);
        formData.append("designation", tutorData.designation);
        !tutor && formData.append("clubId", tutorData.clubId);
        // !tutor && formData.append("password", tutorData.password);
        tutorData.password !== "" &&
          formData.append("password", tutorData.password);
        !tutor
          ? dispatch(actions.createTutor(formData, navigate))
          : dispatch(actions.updateTutor(formData));
        tutor && setTutorDataUpdate({ ...tutorData, id: tutor.id });
        setTutorData({
          name: tutor && tutorData?.name ? tutorData?.name : "",
          email: tutor && tutorData?.email ? tutorData?.email : "",
          image: tutor && tutorData?.image ? tutorData?.image : "",
          designation:
            tutor && tutorData?.designation ? tutorData?.designation : "",
          clubId: user?.club ? user?.club?.id : params?.id ? params?.id : "",
          password: "",
          cPassword: "",
        });
        setTutorImg(tutor && tutorData?.image ? tutorData?.image : null);
        closeRef.current.click();
        setError("");
        setPasswordValid(true);
        setViewPassword(false);
      }
    }
  };

  const handleCancel = () => {
    setTutorData({
      name: tutor?.name ? tutor?.name : "",
      email: tutor?.email ? tutor?.email : "",
      image: tutor?.image ? tutor?.image : "",
      designation: tutor?.designation ? tutor?.designation : "",
      clubId: user?.club ? user?.club?.id : params?.id ? params?.id : "",
      password: "",
      cPassword: "",
    });
    setTutorImg(tutor?.image ? tutor?.image : null);
    setError("");
    setPasswordValid(true);
    setViewPassword(false);
  };

  const handleRemove = () => {
    setTutorData({
      ...tutorData,
      image: "http://s3.amazonaws.com/37assets/svn/765-default-avatar.png",
    });
    setTutorImg(null);
    setCroppedImage(null);
  };

  const generatePassword = (length = 8) => {
    const Allowed = {
      Uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
      Lowers: "qwertyuiopasdfghjklzxcvbnm",
      Numbers: "1234567890",
      Symbols: "!@#$%^&`:|.;*",
    };

    const getRandomCharFromString = (str) =>
      str.charAt(Math.floor(Math.random() * str.length));

    let pwd = "";
    pwd += getRandomCharFromString(Allowed.Uppers); // pwd will have at least one upper
    pwd += getRandomCharFromString(Allowed.Lowers); // pwd will have at least one lower
    pwd += getRandomCharFromString(Allowed.Numbers); // pwd will have at least one number
    pwd += getRandomCharFromString(Allowed.Symbols); // pwd will have at least one symbol
    for (let i = pwd.length; i < length; i++)
      pwd += getRandomCharFromString(Object.values(Allowed).join("")); // fill the rest of the pwd with random characters
    return pwd;
  };

  useEffect(() => {
    (user?.club || params?.id) &&
      setTutorData({
        ...tutorData,
        clubId: user?.club ? user?.club?.id : params?.id ? params?.id : "",
      });
    // eslint-disable-next-line
  }, [user, params?.id]);

  return (
    <>
      {/* <div className="container"> */}
      <div className="addNewClubDiv p-2 bg-white poppins">
        {/* <Heading type="h5" label="Add New Club" />
        <hr className="divider" /> */}
        <div className="modal-header">
          <Heading
            type="h5"
            label={!tutor ? "Add New Tutor" : "Update Tutor"}
            className="modal-title fw-bold"
            id="staticBackdropLabel"
          />
          <Button
            className="btn-close"
            attribute={{ "data-bs-dismiss": "modal", "aria-label": "Close" }}
            handleClick={handleCancel}
          />
          <p
            className="d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref={closeRef}></p>
        </div>
        <div className="modal-body">
          <form>
            <div className="text-center mb-3 mt-1">
              <div
                className={`text-center ${
                  !tutorImg
                    ? "d-flex flex-column justify-content-center align-items-center"
                    : "d-grid placeItem"
                } mx-auto choosePhotoDiv`}>
                {/* {!tutorImg && (
                  <>
                    <FeatherIcon
                      icon="camera"
                      size={24}
                      className="text-muted"
                    />
                    <p className="mt-2">Choose Photo</p>
                  </>
                )} */}

                <Image
                  src={
                    tutorImg
                      ? tutorImg
                      : "http://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
                  }
                  // className="img-fluid addClubImage"
                  className=""
                  aspect={1}
                  style={{ borderRadius: "100%" }}
                  minHeight={100}
                  minWidth={100}
                  maxWidth={100}
                  maxHeight={100}
                  width="100"
                  height="100"
                  alt=""
                />

                <div className="chooseRemoveImgDiv">
                  <div className="chooseImgDiv">
                    <FeatherIcon
                      icon="camera"
                      size={18}
                      onClick={handleClick}
                    />
                  </div>
                  {tutorImg && (
                    <div className="removeImgDiv">
                      <FeatherIcon
                        icon="trash-2"
                        size={18}
                        onClick={handleRemove}
                      />
                    </div>
                  )}
                </div>
              </div>

              <input
                type="file"
                ref={imgRef}
                className="d-none"
                onChange={handleChangeImage}
                accept="image/*"
              />
              <Label className="mt-2" title="Tutor Image" />
              <CropImage
                src={tutorImgValue}
                setCroppedImage={setCroppedImageCallback}
                openCropModal={openCropModal}
                setCropModal={setCropModal}
              />
            </div>

            <div className="mb-3">
              <Input
                type="text"
                className="inputField mt-2 px-2"
                id="tutorName"
                label="Tutor Name"
                name="name"
                divStyle={{ fontSize: "18px" }}
                value={tutorData.name}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="mb-3">
              <div className="d-md-flex">
                <div className="w-100">
                  <Input
                    type="email"
                    className={`inputField mt-2 px-2 ${
                      tutor ? "readOnly" : ""
                    }`}
                    id="tutorEmail"
                    name="email"
                    label="Tutor Email"
                    divStyle={{ fontSize: "18px" }}
                    value={tutorData.email}
                    onChange={(e) => handleChange(e)}
                    readOnly={tutor && true}
                  />
                </div>
                <div className="ms-md-3 ms-0 w-100">
                  <Input
                    type="text"
                    className="inputField mt-2 px-2"
                    id="tutorDesignation"
                    name="designation"
                    label="Tutor Role"
                    divStyle={{ fontSize: "18px" }}
                    value={tutorData.designation}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>

            {
              <div className="mb-3">
                <div className="d-md-flex">
                  <div className="w-100">
                    {/* <Input
                    type="password"
                    className="inputField mt-2 px-2"
                    id="tutorPassword"
                    name="password"
                    label="Tutor Password"
                    divStyle={{ fontSize: "18px" }}
                    value={tutorData.password}
                    onChange={(e) => handleChange(e)}
                  /> */}
                    <div className="createField" style={{ fontSize: "18px" }}>
                      <p>Tutor Password</p>
                      <div className="input-group mt-2">
                        <input
                          type={viewPassword ? "text" : "password"}
                          className="form-control border-end-0"
                          name="password"
                          // placeholder="Password"
                          value={tutorData.password}
                          // onChange={(e) => handleChange(e)}
                          onChange={handleChange}
                          maxLength="16"
                        />
                        <span className="input-group-text bg-white border-start-0 text-secondary">
                          <FeatherIcon
                            icon={!viewPassword ? "eye" : "eye-off"}
                            size={20}
                            onClick={() => setViewPassword(!viewPassword)}
                          />
                        </span>
                        <span
                          className="input-group-text"
                          id="basic-addon2"
                          onClick={() => {
                            let pass = generatePassword(8);
                            setTutorData({
                              ...tutorData,
                              password: pass,
                            });
                            setPasswordValid(checkPassword(pass));
                          }}>
                          Generate
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="ms-md-3 w-100">
                    <Input
                      type="password"
                      className="inputField mt-2 px-2"
                      id="tutorCPassword"
                      name="cPassword"
                      label="Tutor Confirm Password"
                      divStyle={{ fontSize: "18px" }}
                      value={tutorData.cPassword}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                {!passwordValid && (
                  <p
                    style={{ fontSize: "small" }}
                    className="fw-bold text-danger">
                    Your Password is must include at least one uppercase, one
                    lowercase, one special character and 8 to 16 characters
                    long.
                  </p>
                )}
              </div>
            }
            <p style={{ fontSize: "small" }} className="fw-bold text-danger">
              {error}
            </p>
          </form>
        </div>
        <div className="modal-footer">
          <Button
            className="btn btn-outline-secondary me-2"
            label="Cancel"
            attribute={{ "data-bs-dismiss": "modal" }}
            handleClick={handleCancel}
          />
          <Button
            className="btn btn-success button-primary"
            label={!tutor ? "Create" : "Update"}
            handleClick={handleSubmit}
          />
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default AddTutor;

import MainControlButton from "components/MainControlButton";
import React, { useEffect, useState } from "react";
import "./CreateWorkExperience.css";
import Image from "components/Image";
import plus1 from "assets/images/plus1.png";
import Input from "components/Input";
import { Dropdown } from "primereact/dropdown";
import Button from "components/Button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "redux/WorkExperience/action";
import { actions as loaderAction } from "redux/Loader/action";
import { useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

function CreateWorkExperience({ playerCentre, player, setPlayerCentre }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { user } = useSelector((state) => state.user);
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const { workExperiences } = useSelector((state) => state.workExperience);
  const [createWorkExperience, setCreateWorkExperience] = useState(false);
  const [workExperience, setWorkExperience] = useState({
    title: "",
    timeUnit: "hours",
    value: 0,
  });
  const [editWorkExperience, setEditWorkExperience] = useState({ id: "" });

  const times = [
    { name: "Hours", value: "hours" },
    { name: "Days", value: "days" },
    { name: "Weeks", value: "weeks" },
    { name: "Months", value: "months" },
    { name: "Years", value: "years" },
  ];

  useEffect(() => {
    dispatch(loaderAction?.startLoader());
    dispatch(
      actions?.getWorkExperienceTiles({
        clubId: params?.id ? params?.id : user?.club?.id,
      })
    );
  }, []);

  const updateWorkExperience = () => {
    dispatch(loaderAction?.startLoader());
    dispatch(
      actions?.updateWorkExperience({
        id: editWorkExperience.id,
        title: editWorkExperience?.title,
      })
    );
    setEditWorkExperience({ id: "" });
  };

  return (
    <div className="CreateCourseBtn">
      <MainControlButton
        className="MainControlButton"
        playerCentre={playerCentre}
        player={player}
        setPlayerCentre={setPlayerCentre}
      />

      <div>
        <h4>Work Experience</h4>
      </div>

      <div className="mt-3 d-flex gap-3">
        <div
          className="card work-exp-card"
          onClick={() => {
            setCreateWorkExperience(true);
          }}>
          <div>
            <Image
              src={plus1}
              alt=""
              className=""
              style={{ borderRadius: "10px", width: "50px" }}
            />
          </div>
          <p>New Work Experience</p>
        </div>

        {createWorkExperience && (
          <div>
            <div className="d-flex gap-3 create-work-exp">
              <div className="createField mt-0">
                <p className="inputLabel">Work Experience Title</p>
                <Input
                  id="workExperienceTitle"
                  className="border"
                  value={workExperience?.title}
                  name="title"
                  onChange={(e) => {
                    setWorkExperience({
                      ...workExperience,
                      title: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div>
                <p className="inputLabel">Work Experience Time</p>
                <Dropdown
                  value={
                    workExperience?.timeUnit ? workExperience?.timeUnit : ""
                  }
                  name="timeUnit"
                  options={times}
                  onChange={(e) => {
                    workExperience.timeUnit = e.value;
                    setWorkExperience({
                      ...workExperience,
                    });
                  }}
                  optionLabel="name"
                  className="w-100 border mt-2"
                />
              </div>
              <div className="createField mt-0">
                <p className="inputLabel">
                  How Many {workExperience?.timeUnit}?
                </p>
                <Input
                  id="workExperienceTitle"
                  className="border"
                  value={workExperience?.value}
                  name="value"
                  onChange={(e) => {
                    setWorkExperience({
                      ...workExperience,
                      value: e.target.value,
                    });
                  }}
                  type="number"
                />
              </div>
            </div>
            <div>
              <Button
                type="button"
                className="btn btn-success button-primary"
                label="ADD"
                handleClick={() => {
                  dispatch(loaderAction?.startLoader());
                  dispatch(
                    actions?.createWorkExperience({
                      ...workExperience,
                      clubId: params?.id ? params?.id : user?.club?.id,
                    })
                  );
                  setWorkExperience({
                    title: "",
                    timeUnit: "hours",
                    value: 0,
                  });
                  setCreateWorkExperience(false);
                }}
                // disabled={disableCommitButton}
              />
            </div>
          </div>
        )}
      </div>
      <div className="d-flex gap-3 mt-5">
        {workExperiences?.map((workExp) => {
          return (
            <div className="card work-card p-2">
              <div
                className="icon"
                onClick={() => {
                  setEditWorkExperience({ id: workExp?.id, ...workExp });
                }}>
                <FeatherIcon icon={"edit-3"} size={18} className="text-white" />
              </div>
              <div className="card-header">
                <p>WORK EXPERIENCE</p>
              </div>
              <div className="card-body">
                {editWorkExperience?.id !== workExp?.id && (
                  <p>{workExp?.title}</p>
                )}
                {editWorkExperience?.id === workExp?.id && (
                  <div className="d-flex w-100">
                    <div className="w-75">
                      <input
                        id=""
                        className="border w-100"
                        style={{ height: "20px" }}
                        value={editWorkExperience?.title}
                        name="title"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            updateWorkExperience();
                          }
                        }}
                        onChange={(e) => {
                          setEditWorkExperience({
                            ...editWorkExperience,
                            title: e.target.value,
                          });
                        }}
                        type="text"
                      />
                    </div>
                    <div className="checkAndCloseIcon">
                      <FeatherIcon
                        className={`profile-field-EditIcon`}
                        icon={`check`}
                        size={17}
                        stroke-width={3}
                        onClick={updateWorkExperience}
                      />
                      <FeatherIcon
                        className={`profile-field-remove`}
                        icon={`x`}
                        size={17}
                        onClick={() => {
                          setEditWorkExperience({ id: "" });
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between mt-2">
                  <p>Hours</p>
                  <p>{workExp?.value}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CreateWorkExperience;

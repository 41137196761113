import Input from "components/Input";
import ProfileFieldType from "components/ProfileFieldType";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as ProfileAction } from "redux/Profile/action";
import {
  PROFILE_FIELD_TYPES,
  PROFILE_FIELD_VALUE_TYPES,
} from "utils/constants";
import { Toast } from "utils/toast";
import "./CreateProfileField.css";
import { useLocation } from "react-router-dom";
import ProfileFieldArray from "components/ProfileFieldArray";
import { actions as loaderActions } from "redux/Loader/action";

const CreateProfileField = ({
  steps,
  setShowProfileField,
  createNewProfile,
  createProfileFieldFromOrganization,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [fieldTypeValue, setFieldTypeValue] = useState("");
  const [fieldValueType, setFieldValueType] = useState("");
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const { profileFields, dynamicProfileFields, organizationProfileFields } =
    useSelector((state) => state.profile);
  const [fieldName, setFieldName] = useState({
    fieldName: "",
  });
  const [oldNumberOfOption, setOldNumberOfOption] = useState(0);
  const [options, setOptions] = useState([]);
  const { user } = useSelector((state) => state.user);
  // eslint-disable-next-line
  const [profileData, setProfileData] = useState({
    title: "",
    type: "",
    options: [],
    value: "",
    field: "",
    priority: "",
  });
  const [arrayField, setArrayField] = useState({
    arrayType: "",
    placeholder: null,
    objectFields: [],
  });

  const [selectedProfileField, setSelectedProfileField] = useState(null);
  const profileFieldTypes = [
    { name: "Common Profile Field", value: "CP" },
    {
      name:
        window.innerWidth > 425
          ? `${steps?.toLowerCase()} Specific Profile Field`
          : `${
              `${steps}Specific Profile Field`?.length > 30
                ? steps?.slice(0, 30) + "..."
                : steps
            } `,
      value: "DP",
    },
  ];

  useEffect(() => {
    if (fieldName?.fieldName !== "" && fieldTypeValue !== "") {
      setDisableSaveBtn(false);
    }
  }, [fieldName, fieldTypeValue]);

  const handleChangeInput = (event) => {
    setFieldName((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeFieldType = (e) => {
    setFieldTypeValue(e.target.value);
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleSaveBtn = () => {
    dispatch(loaderActions.startLoader());
    if (fieldTypeValue === "Array") {
      if (selectedProfileField === "CP") {
        dispatch(
          ProfileAction.createProfileField(
            {
              id: profileFields.id,
              newProfileData: arrayField?.ojectOfArray
                ? [
                    {
                      // title: fieldName?.fieldName,
                      title: capitalizeFirstLetter(fieldName?.fieldName),
                      type: fieldTypeValue?.toLowerCase(),
                      value: arrayField?.ojectOfArray,
                      userAddable: arrayField?.userAddable === 1 ? true : false,
                      arrayType: arrayField?.arrayType,
                      field: "list",
                    },
                  ]
                : [
                    {
                      // title: fieldName?.fieldName,
                      title: capitalizeFirstLetter(fieldName?.fieldName),
                      type: fieldTypeValue?.toLowerCase(),
                      value: [],
                      userAddable:
                        arrayField?.userAddable === 1 ||
                        arrayField?.userAddable === true
                          ? true
                          : false,
                      arrayType: arrayField?.arrayType,
                      objectFields: arrayField?.objectFields,
                      isContainsMedia: arrayField?.objectFields?.find(
                        (item) => item.type === "media"
                      )
                        ? true
                        : false,
                      field: "list",
                    },
                  ],
            },
            setShowProfileField
          )
        );
      } else {
        if (createProfileFieldFromOrganization) {
          if (!organizationProfileFields) {
            dispatch(
              ProfileAction.createNewOrganizationProfile(
                {
                  instituteId: user?.club?.instituteId,
                  instituteTypeId: user?.club?.instituteTypeId,
                  organisationId: user?.club?.id,
                  profileFields: arrayField?.ojectOfArray
                    ? [
                        {
                          // title: fieldName?.fieldName,
                          title: capitalizeFirstLetter(fieldName?.fieldName),
                          type: fieldTypeValue?.toLowerCase(),
                          value: arrayField?.ojectOfArray,
                          userAddable:
                            arrayField?.userAddable === 1 ? true : false,
                          arrayType: arrayField?.arrayType,
                          field: "list",
                        },
                      ]
                    : [
                        {
                          // title: fieldName?.fieldName,
                          title: capitalizeFirstLetter(fieldName?.fieldName),
                          type: fieldTypeValue?.toLowerCase(),
                          value: [],
                          ...arrayField,
                          isContainsMedia: arrayField?.objectFields?.find(
                            (item) => item.type === "media"
                          )
                            ? true
                            : false,
                          field: "list",
                        },
                      ],
                },
                setShowProfileField
              )
            );
          } else {
            dispatch(
              ProfileAction.createOrganizationProfileField(
                {
                  id: organizationProfileFields?.id,
                  newProfileData: arrayField?.ojectOfArray
                    ? [
                        {
                          // title: fieldName?.fieldName,
                          title: capitalizeFirstLetter(fieldName?.fieldName),
                          type: fieldTypeValue?.toLowerCase(),
                          value: arrayField?.ojectOfArray,
                          userAddable:
                            arrayField?.userAddable === 1 ? true : false,
                          arrayType: arrayField?.arrayType,
                          field: "list",
                        },
                      ]
                    : [
                        {
                          // title: fieldName?.fieldName,
                          title: capitalizeFirstLetter(fieldName?.fieldName),
                          type: fieldTypeValue?.toLowerCase(),
                          value: [],
                          ...arrayField,
                          isContainsMedia: arrayField?.objectFields?.find(
                            (item) => item.type === "media"
                          )
                            ? true
                            : false,
                          field: "list",
                        },
                      ],
                },
                setShowProfileField
              )
            );
          }
        } else {
          dispatch(
            ProfileAction.createDynamicProfileField(
              {
                id: dynamicProfileFields?.id,
                newProfileData: arrayField?.ojectOfArray
                  ? [
                      {
                        // title: fieldName?.fieldName,
                        title: capitalizeFirstLetter(fieldName?.fieldName),
                        type: fieldTypeValue?.toLowerCase(),
                        value: arrayField?.ojectOfArray,
                        userAddable:
                          arrayField?.userAddable === 1 ? true : false,
                        arrayType: arrayField?.arrayType,
                        field: "list",
                      },
                    ]
                  : [
                      {
                        // title: fieldName?.fieldName,
                        title: capitalizeFirstLetter(fieldName?.fieldName),
                        type: fieldTypeValue?.toLowerCase(),
                        value: [],
                        ...arrayField,
                        isContainsMedia: arrayField?.objectFields?.find(
                          (item) => item.type === "media"
                        )
                          ? true
                          : false,
                        field: "list",
                      },
                    ],
              },
              setShowProfileField
            )
          );
        }
      }
      setProfileData({
        title: fieldName?.fieldName,
        type: fieldTypeValue,
        options: options,
        value: "",
      });
    } else if (
      fieldTypeValue === "Radio" ||
      fieldTypeValue === "Select" ||
      fieldTypeValue === "Multi-Select"
    ) {
      if (options.length > 0) {
        // CP = custom profile Field
        if (selectedProfileField === "CP") {
          dispatch(
            ProfileAction.createProfileField(
              {
                id: profileFields?.id,
                newProfileData: [
                  {
                    // title: fieldName?.fieldName,
                    title: capitalizeFirstLetter(fieldName?.fieldName),
                    type: "string",
                    options: options,
                    value: "",
                    field: "select",
                    // priority: 0
                  },
                ],
              },
              setShowProfileField
            )
          );
        } else {
          if (createProfileFieldFromOrganization) {
            if (!organizationProfileFields) {
              dispatch(
                ProfileAction.createNewOrganizationProfile(
                  {
                    instituteId: user?.club?.instituteId,
                    instituteTypeId: user?.club?.instituteTypeId,
                    organisationId: user?.club?.id,
                    profileFields: [
                      {
                        // title: fieldName?.fieldName,
                        title: capitalizeFirstLetter(fieldName?.fieldName),
                        type: "string",
                        options: options,
                        value: "",
                        field: "select",
                      },
                    ],
                  },
                  setShowProfileField
                )
              );
            } else {
              dispatch(
                ProfileAction.createOrganizationProfileField(
                  {
                    id: organizationProfileFields?.id,
                    newProfileData: [
                      {
                        // title: fieldName?.fieldName,
                        title: capitalizeFirstLetter(fieldName?.fieldName),
                        type: "string",
                        options: options,
                        value: "",
                        field: "select",
                      },
                    ],
                  },
                  setShowProfileField
                )
              );
            }
          } else {
            dispatch(
              ProfileAction.createDynamicProfileField(
                {
                  id: dynamicProfileFields?.id,
                  newProfileData: [
                    {
                      // title: fieldName?.fieldName,
                      title: capitalizeFirstLetter(fieldName?.fieldName),
                      type: "string",
                      options: options,
                      value: "",
                      field: "select",
                    },
                  ],
                },
                setShowProfileField
              )
            );
          }
        }
        setProfileData({
          title: fieldName?.fieldName,
          type: fieldTypeValue,
          options: options,
          value: "",
        });
      } else {
        Toast("error", "Please add options!");
      }
    } else if (fieldTypeValue === "Image") {
      if (
        createNewProfile &&
        !dynamicProfileFields &&
        !createProfileFieldFromOrganization
      ) {
        dispatch(
          ProfileAction.createNewDynamicProfile(
            {
              instituteId: location?.state?.instituteId,
              instituteTypeId: location?.state?.instituteTypeId,
              profileFields: [
                {
                  // title: fieldName?.fieldName,
                  title: capitalizeFirstLetter(fieldName?.fieldName),
                  type: "string",
                  value: "",
                  field: "image",
                },
              ],
            },
            setShowProfileField
          )
        );
      } else {
        if (!createProfileFieldFromOrganization) {
          if (selectedProfileField === "CP") {
            dispatch(
              ProfileAction.createProfileField(
                {
                  id: profileFields?.id,
                  newProfileData: [
                    {
                      // title: fieldName?.fieldName,
                      title: capitalizeFirstLetter(fieldName?.fieldName),
                      type: "string",
                      value: "",
                      field: "image",
                    },
                  ],
                },
                setShowProfileField
              )
            );
          } else {
            if (dynamicProfileFields) {
              dispatch(
                ProfileAction.createDynamicProfileField(
                  {
                    id: dynamicProfileFields?.id,
                    newProfileData: [
                      {
                        // title: fieldName?.fieldName,
                        title: capitalizeFirstLetter(fieldName?.fieldName),
                        type: "string",
                        value: "",
                        field: "image",
                      },
                    ],
                  },
                  setShowProfileField
                )
              );
            } else {
              dispatch(
                ProfileAction.createNewDynamicProfile(
                  {
                    instituteId: location?.state?.instituteId,
                    instituteTypeId: location?.state?.instituteTypeId,
                    profileFields: [
                      {
                        // title: fieldName?.fieldName,
                        title: capitalizeFirstLetter(fieldName?.fieldName),
                        type: "string",
                        value: "",
                        field: "image",
                      },
                    ],
                  },
                  setShowProfileField
                )
              );
            }
          }
        }
      }
      if (createProfileFieldFromOrganization) {
        if (!organizationProfileFields) {
          dispatch(
            ProfileAction.createNewOrganizationProfile(
              {
                instituteId: user?.club?.instituteId,
                instituteTypeId: user?.club?.instituteTypeId,
                organisationId: user?.club?.id,
                profileFields: [
                  {
                    // title: fieldName?.fieldName,
                    title: capitalizeFirstLetter(fieldName?.fieldName),
                    type: "string",
                    value: "",
                    field: "image",
                  },
                ],
              },
              setShowProfileField
            )
          );
        } else {
          dispatch(
            ProfileAction.createOrganizationProfileField(
              {
                id: organizationProfileFields?.id,
                newProfileData: [
                  {
                    // title: fieldName?.fieldName,
                    title: capitalizeFirstLetter(fieldName?.fieldName),
                    type: "string",
                    value: "",
                    field: "image",
                  },
                ],
              },
              setShowProfileField
            )
          );
        }
      }
    } else if (fieldTypeValue === "Boolean") {
      if (
        createNewProfile &&
        !dynamicProfileFields &&
        !createProfileFieldFromOrganization
      ) {
        dispatch(
          ProfileAction.createNewDynamicProfile(
            {
              instituteId: location?.state?.instituteId,
              instituteTypeId: location?.state?.instituteTypeId,
              profileFields: [
                {
                  // title: fieldName?.fieldName,
                  title: capitalizeFirstLetter(fieldName?.fieldName),
                  type: fieldTypeValue?.toLowerCase(),
                  value: "",
                  field: "boolean",
                },
              ],
            },
            setShowProfileField
          )
        );
      } else {
        if (!createProfileFieldFromOrganization) {
          if (selectedProfileField === "CP") {
            dispatch(
              ProfileAction.createProfileField(
                {
                  id: profileFields.id,
                  newProfileData: [
                    {
                      // title: fieldName?.fieldName,
                      title: capitalizeFirstLetter(fieldName?.fieldName),
                      type: fieldTypeValue?.toLowerCase(),
                      value: "",
                      field: "boolean",
                    },
                  ],
                },
                setShowProfileField
              )
            );
          } else {
            if (dynamicProfileFields) {
              dispatch(
                ProfileAction.createDynamicProfileField(
                  {
                    id: dynamicProfileFields?.id,
                    newProfileData: [
                      {
                        // title: fieldName?.fieldName,
                        title: capitalizeFirstLetter(fieldName?.fieldName),
                        type: fieldTypeValue?.toLowerCase(),
                        value: "",
                        field: "boolean",
                      },
                    ],
                  },
                  setShowProfileField
                )
              );
            } else {
              dispatch(
                ProfileAction.createNewDynamicProfile(
                  {
                    instituteId: location?.state?.instituteId,
                    instituteTypeId: location?.state?.instituteTypeId,
                    profileFields: [
                      {
                        // title: fieldName?.fieldName,
                        title: capitalizeFirstLetter(fieldName?.fieldName),
                        type: fieldTypeValue?.toLowerCase(),
                        value: "",
                        field: "boolean",
                      },
                    ],
                  },
                  setShowProfileField
                )
              );
            }
          }
        }
      }
      if (createProfileFieldFromOrganization) {
        if (!organizationProfileFields) {
          dispatch(
            ProfileAction.createNewOrganizationProfile(
              {
                instituteId: user?.club?.instituteId,
                instituteTypeId: user?.club?.instituteTypeId,
                organisationId: user?.club?.id,
                profileFields: [
                  {
                    // title: fieldName?.fieldName,
                    title: capitalizeFirstLetter(fieldName?.fieldName),
                    type: fieldTypeValue?.toLowerCase(),
                    value: "",
                    field: "boolean",
                  },
                ],
              },
              setShowProfileField
            )
          );
        } else {
          dispatch(
            ProfileAction.createOrganizationProfileField(
              {
                id: organizationProfileFields?.id,
                newProfileData: [
                  {
                    // title: fieldName?.fieldName,
                    title: capitalizeFirstLetter(fieldName?.fieldName),
                    type: fieldTypeValue?.toLowerCase(),
                    value: "",
                    field: "boolean",
                  },
                ],
              },
              setShowProfileField
            )
          );
        }
      }
      setProfileData({
        // title: fieldName?.fieldName,
        title: capitalizeFirstLetter(fieldName?.fieldName),
        type: fieldTypeValue,
        value: "",
      });
    } else {
      if (
        createNewProfile &&
        !dynamicProfileFields &&
        !createProfileFieldFromOrganization
      ) {
        dispatch(
          ProfileAction.createNewDynamicProfile(
            {
              instituteId: location?.state?.instituteId,
              instituteTypeId: location?.state?.instituteTypeId,
              profileFields: [
                {
                  // title: fieldName?.fieldName,
                  title: capitalizeFirstLetter(fieldName?.fieldName),
                  type: fieldTypeValue?.toLowerCase(),
                  value: "",
                  field:
                    fieldTypeValue?.toLowerCase() === "string"
                      ? fieldValueType
                      : "textfield",
                },
              ],
            },
            setShowProfileField
          )
        );
      } else {
        if (!createProfileFieldFromOrganization) {
          if (selectedProfileField === "CP") {
            dispatch(
              ProfileAction.createProfileField(
                {
                  id: profileFields.id,
                  newProfileData: [
                    {
                      // title: fieldName?.fieldName,
                      title: capitalizeFirstLetter(fieldName?.fieldName),
                      type: fieldTypeValue?.toLowerCase(),
                      value: "",
                      field:
                        fieldTypeValue?.toLowerCase() === "string"
                          ? fieldValueType
                          : "textfield",
                    },
                  ],
                },
                setShowProfileField
              )
            );
          } else {
            if (dynamicProfileFields) {
              dispatch(
                ProfileAction.createDynamicProfileField(
                  {
                    id: dynamicProfileFields?.id,
                    newProfileData: [
                      {
                        // title: fieldName?.fieldName,
                        title: capitalizeFirstLetter(fieldName?.fieldName),
                        type: fieldTypeValue?.toLowerCase(),
                        value: "",
                        field:
                          fieldTypeValue?.toLowerCase() === "string"
                            ? fieldValueType
                            : "textfield",
                      },
                    ],
                  },
                  setShowProfileField
                )
              );
            } else {
              dispatch(
                ProfileAction.createNewDynamicProfile(
                  {
                    instituteId: location?.state?.instituteId,
                    instituteTypeId: location?.state?.instituteTypeId,
                    profileFields: [
                      {
                        // title: fieldName?.fieldName,
                        title: capitalizeFirstLetter(fieldName?.fieldName),
                        type: fieldTypeValue?.toLowerCase(),
                        value: "",
                        field:
                          fieldTypeValue?.toLowerCase() === "string"
                            ? fieldValueType
                            : "textfield",
                      },
                    ],
                  },
                  setShowProfileField
                )
              );
            }
          }
        }
      }
      if (createProfileFieldFromOrganization) {
        if (!organizationProfileFields) {
          dispatch(
            ProfileAction.createNewOrganizationProfile(
              {
                instituteId: user?.club?.instituteId,
                instituteTypeId: user?.club?.instituteTypeId,
                organisationId: user?.club?.id,
                profileFields: [
                  {
                    // title: fieldName?.fieldName,
                    title: capitalizeFirstLetter(fieldName?.fieldName),
                    type: fieldTypeValue?.toLowerCase(),
                    value: "",
                    field:
                      fieldTypeValue?.toLowerCase() === "string"
                        ? fieldValueType
                        : "textfield",
                  },
                ],
              },
              setShowProfileField
            )
          );
        } else {
          dispatch(
            ProfileAction.createOrganizationProfileField(
              {
                id: organizationProfileFields?.id,
                newProfileData: [
                  {
                    // title: fieldName?.fieldName,
                    title: capitalizeFirstLetter(fieldName?.fieldName),
                    type: fieldTypeValue?.toLowerCase(),
                    value: "",
                    field:
                      fieldTypeValue?.toLowerCase() === "string"
                        ? fieldValueType
                        : "textfield",
                  },
                ],
              },
              setShowProfileField
            )
          );
        }
      }
      setProfileData({
        // title: fieldName?.fieldName,
        title: capitalizeFirstLetter(fieldName?.fieldName),
        type: fieldTypeValue,
        value: "",
      });
    }
  };

  return (
    <>
      <div className="create-profile-field mt-3">
        <div className="d-flex gap-3 profile-line">
          <div className="w-25 create-profile-input">
            <Input
              type="text"
              className={`profile-inputField mt-2 px-2`}
              name="fieldName"
              label="Field Name"
              value={fieldName?.fieldName}
              divStyle={{ fontSize: "18px" }}
              onChange={handleChangeInput}
              divClass="poppins createField"
            />
          </div>
          {!createNewProfile && !createProfileFieldFromOrganization && (
            <div className="profile-fields-dropdown">
              <p className="poppins my-2">
                Select profile type which you want to add!
              </p>
              <Dropdown
                value={selectedProfileField}
                onChange={(e) => setSelectedProfileField(e.value)}
                options={profileFieldTypes}
                optionLabel="name"
                placeholder="Select a profile type"
                className="w-full profile-fields-dropdown"
              />
            </div>
          )}
          {/* <div className="create-profile-saveBtn saveBtn-hide">
            <button
              type="button"
              id="profileFieldSaveBtn"
              // className="btn user-profile-saveBtn "
              className={`btn user-profile-saveBtn ${
                disableSaveBtn === true ? "save-btn-disable" : ""
              }`}
              onClick={handleSaveBtn}
              disabled={disableSaveBtn ? true : false}
            >
              Save
            </button>
          </div> */}
        </div>

        <div>
          <div className="mt-3 poppins">
            <span className="profile-filed-type">Field Types</span>
          </div>
          <div className="radio-inputs my-3">
            {PROFILE_FIELD_TYPES &&
              PROFILE_FIELD_TYPES?.length > 0 &&
              PROFILE_FIELD_TYPES?.map((item) => (
                <label>
                  <input
                    className="radio-input"
                    type="radio"
                    name="engine"
                    value={item?.typeName}
                    onChange={handleChangeFieldType}
                  />
                  <span className="radio-tile">
                    <span className="radio-label">{item?.typeName}</span>
                  </span>
                </label>
              ))}
          </div>
        </div>

        {fieldTypeValue === "String" && (
          <div>
            <div className="mt-3 poppins">
              <span className="profile-filed-type">Type OF String</span>
            </div>
            <div className="radio-inputs my-3">
              {PROFILE_FIELD_VALUE_TYPES &&
                PROFILE_FIELD_VALUE_TYPES?.length > 0 &&
                PROFILE_FIELD_VALUE_TYPES?.map((item) => (
                  <label>
                    <input
                      className="radio-input"
                      type="radio"
                      name="engine"
                      value={item?.value}
                      onChange={(e) => {
                        setFieldValueType(e.target.value);
                      }}
                    />
                    <span className="radio-tile">
                      <span className="radio-label">{item?.typeName}</span>
                    </span>
                  </label>
                ))}
            </div>
          </div>
        )}

        {/* <ProfileContext.Provider
          value={{
            oldNumberOfOption,
            setOldNumberOfOption,
            options,
            setOptions,
          }}
        >
          {(fieldTypeValue === "Radio" ||
            fieldTypeValue === "Select" ||
            fieldTypeValue === "Multi-Select") && <ProfileFieldType />}
        </ProfileContext.Provider> */}

        {(fieldTypeValue === "Radio" ||
          fieldTypeValue === "Select" ||
          fieldTypeValue === "Multi-Select") && (
          <ProfileFieldType
            oldNumberOfOption={oldNumberOfOption}
            setOldNumberOfOption={setOldNumberOfOption}
            options={options}
            setOptions={setOptions}
          />
        )}

        {fieldTypeValue === "Array" && (
          <ProfileFieldArray
            arrayField={arrayField}
            setArrayField={setArrayField}
          />
        )}
        {/* This button show when display size minimum 769px  */}
        <div className="create-profile-saveBtn profile-saveBtn my-3">
          {!networkProgressDialog ? (
            <button
              type="button"
              id="profileFieldSaveBtn"
              className={`btn user-profile-saveBtn ${
                disableSaveBtn === true ? "save-btn-disable" : ""
              }`}
              onClick={handleSaveBtn}
              disabled={disableSaveBtn ? true : false}>
              Save
            </button>
          ) : (
            <button
              className="btn btn-success border-color-primary background-primary"
              type="button"
              disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              Loading...
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateProfileField;

import { combineReducers } from "redux";
import clubReducer from "./Club/reducer";
import cvReducer from "./CV/reducer";
import loaderReducer from "./Loader/reducer";
import messageReducer from "./Message/reducer";
import playerReducer from "./Player/reducer";
import tutorReducer from "./Tutor/reducer";
import userReducer from "./User/reducer";
import courseReducer from "./Course/reducer";
import profileReducer from "./Profile/reducer";
import workExperienceReducer from "./WorkExperience/reducer";
import brandReducer from "./Brand/reducer";

const combinedReducer = combineReducers({
  user: userReducer,
  players: playerReducer,
  messages: messageReducer,
  cv: cvReducer,
  loader: loaderReducer,
  club: clubReducer,
  tutor: tutorReducer,
  course: courseReducer,
  profile: profileReducer,
  workExperience: workExperienceReducer,
  brand: brandReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT_REQUEST") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;

import React from "react";

function Icons(props) {
  const { width, height, color, name, stroke } = props;
  return (
    <>
      {name === "search" && (
        <svg
          width={width ? width : "26"}
          viewBox="0 0 26 26"
          aria-label="Search icon">
          <circle
            className="path"
            cx="11.35"
            cy="11.35"
            r="6"
            style={{
              fill: color ? color : "none",
              stroke: stroke ? stroke : "#006b00",
              strokeMiterlimit: "10",
              strokeWidth: "1.5px",
            }}></circle>
          <line
            className="path"
            x1="15.59"
            y1="15.59"
            x2="20.65"
            y2="20.65"
            style={{
              fill: color ? color : "none",
              stroke: stroke ? stroke : "#006b00",
              strokeMiterlimit: "10",
              strokeWidth: "1.5px",
            }}></line>
        </svg>
      )}

      {name === "leftArrow" && (
        <svg
          width={width ? width : "16"}
          height={height ? height : "16"}
          viewBox="0 0 16 15"
          fill={color ? color : "none"}
          xmlns="http://www.w3.org/2000/svg"
          className="mt-1 pt-1">
          <path
            d="M2.03223 13.3057V7.70703C2.07617 7.90918 2.18164 8.08496 2.32227 8.23438L7.85938 13.8242C8.07031 14.0352 8.31641 14.1406 8.57129 14.1406C9.13379 14.1406 9.53809 13.7451 9.53809 13.2002C9.53809 12.9189 9.42383 12.6816 9.23926 12.4971L7.47266 10.7041L4.92383 8.39258L6.77832 8.48047H14.8994C15.4883 8.48047 15.8926 8.08496 15.8926 7.50488C15.8926 6.9248 15.4883 6.52051 14.8994 6.52051H6.77832L4.93262 6.6084L7.47266 4.30566L9.23926 2.50391C9.41504 2.32812 9.53809 2.08203 9.53809 1.80078C9.53809 1.26465 9.13379 0.860352 8.57129 0.860352C8.31641 0.860352 8.07031 0.96582 7.85938 1.17676L2.32227 6.77539C2.18164 6.9248 2.07617 7.10059 2.03223 7.29395V1.71289C2.03223 1.16797 1.61914 0.763672 1.06543 0.763672C0.520508 0.763672 0.107422 1.16797 0.107422 1.71289V13.3057C0.107422 13.8506 0.520508 14.2461 1.06543 14.2461C1.61914 14.2461 2.03223 13.8506 2.03223 13.3057Z"
            fill="#445558"
          />
        </svg>
      )}

      {name === "download" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill={color ? color : "none"}
          stroke="currentColor"
          strokeWidth="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="feather feather-download">
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="7 10 12 15 17 10"></polyline>
          <line x1="12" y1="15" x2="12" y2="3"></line>
        </svg>
      )}

      {name === "upload" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          color="#b9b9b9"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-upload-cloud">
          <polyline points="16 16 12 12 8 16"></polyline>
          <line x1="12" y1="12" x2="12" y2="21"></line>
          <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
          <polyline points="16 16 12 12 8 16"></polyline>
        </svg>
      )}
    </>
  );
}

export default Icons;
